import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import { Text } from "@nextui-org/react";
import { listReservations } from "../../graphql/queries";
import { ListReservationsQuery } from "../../API";
import { useUser } from "@clerk/clerk-react";
import Layout from "../Layout";
import Section from "../Section";
import { Reservation } from "../../localDB/types";
import { ReservationRow } from "./ReservationRow";
import { SyncLoader } from "react-spinners";
import { useQuery } from "@tanstack/react-query";

const getReservations = async (patientId?: string): Promise<any> => {
  if (!patientId) {
    return;
  }

  const response = await API.graphql<GraphQLQuery<ListReservationsQuery>>({
    query: listReservations,
    variables: { filter: { patientId: { eq: patientId } } },
  });
  return response;
};

const ReservationList = () => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const patientId = user.user?.id;

  const { status, data } = useQuery({
    queryKey: ["reservations", patientId],
    queryFn: () => getReservations(patientId),
    enabled: Boolean(patientId),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    // staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (status === "success" && data?.data?.listReservations?.items) {
      setReservations(data?.data?.listReservations?.items as Reservation[]);
      setLoading(false);
    }
  }, [status, data]);

  return (
    <Layout>
      <Section>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="flex justify-center items-center h-screen">
              <SyncLoader color="#0068d3" speedMultiplier={0.7} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex">
              <h1 className="font-bold text-3xl md:text-4xl text-gringoPrimaryDark mt-10 md:mt:16">
                My Reservations
              </h1>
            </div>
            <div className="flex flex-col gap-5">
              {reservations.length > 0 ? (
                reservations.map((reservation) => (
                  <div key={reservation.id}>
                    {reservation.state === "PENDING_GRINGO_FEE" ? undefined : (
                      <ReservationRow
                        reservation={reservation}
                      ></ReservationRow>
                    )}
                  </div>
                ))
              ) : (
                <span className="flex p-20 text-md font-semibold justify-center text-stone-600">
                  You don't have any current reservations
                </span>
              )}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default ReservationList;
