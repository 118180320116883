import Layout from "./Layout";
import Section from "./Section";

const AboutScreen = () => {
  return (
    <Layout>
      <Section>
        <>
          <div className="flex flex-col">
            <h1 className="text-3xl md:text-4xl text-left font-bold text-gringoPrimaryDark mt-10 md:mt-16 mb-5">
              About Us
            </h1>
            <div className="flex flex-col items-left rounded-3xl p-5 bg-gringoGray">
              <p className="text-left text-gringoPrimaryDark text-md md:text-lg pt-1  whitespace-pre-line">
                {`GrinGo is an American company proudly headquartered in Gainesville, Florida, with strategic operations directed from Lima, Peru in the heart of Latin America.

                We are driven by a simple belief: everyone deserves a radiant smile without the hefty price tag. Our mission is to transform dental care by making it possible for you to receive top-notch, affordable treatment abroad— all while enjoying a vacation. By exclusively partnering with the best dental clinics across Latin America and leveraging the power of technology, we ensure we're always within reach for our patients.`}
              </p>
            </div>
            <h1 className="text-3xl md:text-4xl text-left font-bold text-gringoPrimaryDark mt-16 mb-5">
              Our Story
            </h1>
            <div className="flex flex-col items-left rounded-3xl p-5 bg-gringoGray">
              <p className="text-left text-gringoPrimaryDark text-md md:text-lg pt-1 whitespace-pre-line">
                {`GrinGo started because we couldn’t understand why dental care in the USA is so expensive (we still can’t), so we decided to find a better way. The result? GrinGo, a comprehensive platform that connects you with top-notch, affordable dental services in Latin America. Included in the cost of our services you’ll get 24/7 local and in-country support from our GrinGo team.`}
              </p>
            </div>
            <h1 className="text-3xl md:text-4xl text-left font-bold text-gringoPrimaryDark mt-16 mb-5">
              Our Mission
            </h1>
            <div className="flex flex-col items-left rounded-3xl p-5 bg-gringoGray">
              <p className="text-left text-gringoPrimaryDark text-md md:text-lg pt-1 whitespace-pre-line">
                {`We understand how daunting it can be to travel abroad for health care, so we are committed to make that experience easy for you. 
Our mission it to provide a high-quality, secure, transparent, and personalized experience, empowering you to reclaim control over your health care—all while enjoying a relaxing vacation.`}
              </p>
            </div>
            <h1 className="text-3xl md:text-4xl text-left font-bold text-gringoPrimaryDark mt-16 mb-5">
              Contact
            </h1>
            <div className="flex flex-col items-left rounded-3xl p-5 mb-10 bg-gringoGray">
              <p className="text-left text-gringoPrimaryDark text-md md:text-lg pt-1 whitespace-pre-line">
                {`Want to learn more or get started? 
Email us at `}
                <a href="mailto:contact@gringo.care">contact@gringo.care</a>
                {`, call us at +1 (617) 237-0201, or `}
                <a href="/">get a quote</a>
                {`. We're here to assist you every step of the way.`}
              </p>
            </div>
          </div>
        </>
      </Section>
    </Layout>
  );
};

export default AboutScreen;
