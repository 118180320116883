import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { GetClinicQuery } from "../API";
import { getClinic } from "../graphql/queries";
import { mapGetClinicResponse } from "../localDB/mappers";
import { Clinic } from "../localDB/types";
import { GraphQLQuery } from "@aws-amplify/api";

const getClinicById = (id: string) =>
  API.graphql<GraphQLQuery<GetClinicQuery>>({
    query: getClinic,
    variables: { id },
  }).then((response) => {
    const clinic = mapGetClinicResponse(response);
    if (!clinic) {
      throw new Error("Clinic not found");
    }
    return clinic;
  });

export default function useClinicDetails(id: string) {
  const {
    data: clinic,
    status,
    isLoading,

    error,
  } = useQuery<Clinic, Error>(["clinic", id], () => getClinicById(id), {
    enabled: Boolean(id),
  });

  return { clinic, status, isLoading, error };
}
