import { useUser } from "@clerk/clerk-react";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

interface IntercomProviderWithUserProps {
  children: React.ReactNode;
}
export const IntercomProviderWithUser = ({
  children,
}: IntercomProviderWithUserProps) => {
  const { user } = useUser();
  const { update } = useIntercom();

  function toUnixTimestamp(date: Date | undefined | null) {
    if (!date) {
      const currDate = new Date();
      return Math.floor(currDate.getTime() / 1000);
    }
    return Math.floor(date?.getTime() / 1000);
  }

  useEffect(() => {
    update({
      name: user?.fullName ?? undefined,
      email: user?.emailAddresses[0]?.emailAddress ?? undefined,
      userId: user?.id,
      createdAt: toUnixTimestamp(user?.createdAt),
    });
  }, [update, user]);

  return <div>{children}</div>;
};
