import React, { useEffect, useState } from "react";
import { ServiceWithQuantity } from "./ClinicDetails";
import { ServiceRow } from "../ServiceRow";
import { ServicesPriceCalculations } from "./ServicesPriceCalculations";
import { Divider } from "../Divider";

interface ServicesCostCalculatorProps {
  services: ServiceWithQuantity[];
  onServicesChange: (index: number, value: number) => void;
}

const ServicesCostCalculator: React.FC<ServicesCostCalculatorProps> = ({
  services,
  onServicesChange,
}) => {
  const [cost, setCost] = useState<number>(0);
  const [comparableCost, setComparableCost] = useState<number>(0);

  useEffect(() => {
    let costCount = 0;
    let comparableCostCount = 0;
    for (const service of services) {
      costCount += service.cost * service.quantity;
      comparableCostCount += service.compareCost! * service.quantity;
    }
    setCost(costCount);
    setComparableCost(comparableCostCount);
  }, [services]);

  const handleServiceChange = (index: number, value: number) => {
    onServicesChange(index, value);
  };

  return (
    <>
      <div className="grid grid-cols-3 mt-5">
        <div className="col-start-1 flex items-center">
          <h2 className="text-lg font-extrabold text-center text-gringoPrimaryDark">
            Service
          </h2>
        </div>
        <div className="col-start-2 flex items-center justify-center">
          <h2 className="text-lg text-center text-gringoPrimaryDark">
            Cost with GrinGo
          </h2>
        </div>
        <div className="col-start-3 flex items-center justify-end">
          <h2 className="text-lg text-center text-gringoPrimaryDark">
            Quantity
          </h2>
        </div>
      </div>
      <Divider />
      {services
        ?.sort((a, b) => a.cost - b.cost)
        .map((service, indexX) => (
          <ServiceRow
            key={service.name}
            service={service}
            onChange={(value) => {
              handleServiceChange(indexX, Number(value));
            }}
          />
        ))}
      <Divider />
      <ServicesPriceCalculations cost={cost} comparableCost={comparableCost} />
    </>
  );
};

export default ServicesCostCalculator;
