export interface FaqsContent {
  question: string;
  answer: string;
}

export interface faqGroup {
  title: string;
  faqs: FaqsContent[];
}

export const faqGroups: faqGroup[] = [
  {
    title: "General Information",
    faqs: [
      {
        question: "What is healthcare tourism?",
        answer:
          "Healthcare tourism refers to the practice of traveling to another country to seek medical or dental treatments, often due to cost savings or access to high-quality care.",
      },
      {
        question:
          "Why should I consider dental services in Latin America through GrinGo?",
        answer:
          "GrinGo provides access to affordable, high-quality dental services in Latin America. Our services come with the added benefits of a secure experience, including a dedicated Patient Advocate who will accompany you to every doctor's appointment, and a GrinGo driver to ensure safe transportation during your stay. Right from the start, our team ensures precise diagnostics and transparent pricing. Additionally, all your payments will be processed through our secure US-based payment platform. GrinGo offers convenience, savings, and personalized support throughout your dental journey.",
      },
      {
        question: "Do the dentists speak English?",
        answer:
          "Yes, the dentists at our partner clinics in Latin America are fluent in English, ensuring effective communication and a comfortable experience for English-speaking patients. Also, your dedicated Patient Advocate speaks English too.",
      },
      {
        question: "Why are your services cheaper than in the US?",
        answer:
          "We think that the right question to ask is: why are dental services so expensive in the US? Our clinics in Latin America count with the latest technology, and extremely skilled dentists.",
      },
    ],
  },
  {
    title: "GrinGo Services and Quality Assurance",
    faqs: [
      {
        question:
          "How does GrinGo ensure the quality and safety of dental clinics/dentists in Latin America?",
        answer:
          "GrinGo carefully selects top-tier dental clinics and dentists in Latin America that meet strict, international quality standards. We thoroughly evaluate their expertise, facilities, and reputation to ensure the highest level of care and patient satisfaction.",
      },
      {
        question: "How will my Patient Advocate help me?",
        answer:
          "Our Patient Advocates have experience in the dental industry, and some even have dental degrees. They will give you personalized guidance and support throughout your healthcare journey, assisting with clinic appointments, accurate diagnosis, and pricing before your trip. During your trip, your Patient Advocate will accompany you to every appointment, providing seamless support and peace of mind.",
      },
      // {
      //   question:
      //     "How can I trust the pricing and diagnosis provided by GrinGo?",
      //   answer: "XXX",
      // },
      {
        question: "What are the payment options for GrinGo services?",
        answer:
          "We offer secure payment options through our US-based payment system powered by Stripe. You can choose to pay using any credit card.",
      },
      {
        question: "Are there any additional costs or hidden fees?",
        answer:
          "GrinGo believes in transparent pricing. We strive to provide you with comprehensive cost estimates upfront, ensuring there are no hidden fees or surprises. However, in about 10% of the surgeries, patients need additional services, such as bone grafting. This would have a low and transparent additional cost case if needed.",
      },
    ],
  },
  {
    title: "Trip Planning and Logistics",
    faqs: [
      {
        question:
          "How far in advance should I start my reservation through GrinGo?",
        answer:
          "We recommend you to start the process one month before your dental work because it allows effective planning, securing travel tickets, and arranging accommodation with the assistance of our dedicated Patient Advocate. Starting the process one month in advance is a common choice among our patients, ensuring a well-organized and seamless dental journey.",
      },
      {
        question: "Where can I stay at my destination?",
        answer:
          "GrinGo will help you arrange your accommodation with one of our trusted partner hotels in Latin America. Our Patient Advocate will help you choose the most suitable option based on your preferences and proximity to the dental clinic.",
      },
      {
        question:
          "What if I need additional assistance or support during my trip?",
        answer:
          "GrinGo is committed to providing continuous support during your trip. Our dedicated Patient Advocate will be available to address any concerns, answer questions, and offer necessary assistance throughout your stay.",
      },
      {
        question: "Which documents are required to travel?",
        answer:
          "Travel requirements vary based on your destination and nationality. Our team will provide guidance on the necessary documents, such as a valid passport and any specific document required for your visit to Latin America",
      },
    ],
  },
  {
    title: "Dental Procedure Specifics",
    faqs: [
      {
        question:
          "How long do I need to stay at the destination where I’ll get my dental work?",
        answer:
          "The duration of your stay at the destination depends on the specific dental service you require. For simpler interventions, a stay of around 7 days is typically sufficient. However, for more complex services like the All-on-4 procedure, we recommend planning for a stay of around 10 to 12 days. Your dedicated Patient Advocate will assist you in determining the appropriate duration based on your specific treatment plan and guide you throughout the process.",
      },
      {
        question:
          "In a complex procedure, will I need to travel back to the destination for the second part?",
        answer:
          "Yes. When receiving complex procedures, your mouth needs to heal for 4+ months. Don't trust any clinic that says they can do it in less time.",
      },
    ],
  },
];
