interface StepCardProps {
  image?: string; // Made it optional for better flexibility.
  title: string;
  stepNumber: number;
  description: string;
  className?: string;
}

export const StepCard = ({
  image,
  title,
  stepNumber,
  description,
  className,
}: StepCardProps) => {
  return (
    <div
      className={
        `flex flex-col w-full space-y-4 px-3 md:max-w-full ` + className
      }
    >
      <div
        className={`rounded-3xl p-5 h-48 flex flex-col justify-between 
            ${image ? "bg-cover bg-center bg-no-repeat" : "bg-gringoYellow"}`}
        style={image ? { backgroundImage: `url(${image})` } : {}}
      >
        <div></div>
        <div className="flex flex-row items-center mt-4">
          <div className="bg-gringoPrimaryDark w-10 h-10 rounded-full flex items-center justify-center">
            <p className="font-bold text-2xl text-gringoYellow">{stepNumber}</p>
          </div>
          <p className="text-left ml-2 font-bold text-2xl text-gringoPrimaryDark">
            {title}
          </p>
        </div>
      </div>
      <p className="text-left ml-2 text-md text-gringoPrimaryDark break-normal">
        {description}
      </p>
    </div>
  );
};
