/* eslint-disable jsx-a11y/alt-text */
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import EffectCarousel from "./effect-carousel.esm.js";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/navigation";
import "./effect-carousel.css";
import "./MySwiper.css";
import { DollarSign, HeartHandshake, Medal, Plane } from "lucide-react";

export default function SwiperHome() {
  return (
    <>
      <Swiper
        modules={[Navigation, EffectCarousel]}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={true}
        effect={"carousel"}
        navigation={{ enabled: false }}
        breakpoints={{ 768: { navigation: { enabled: true } } }}
      >
        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <HeartHandshake color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              Support From Start to Finish
            </p>
            <p className="text-left text-lg">
              A personalized Patient Advocate will come with you to every
              dentist appointment
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <DollarSign color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              US Payment Processing
            </p>
            <p className="text-left text-lg">
              Pay through our secure, US-based platform
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <Medal color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">Top-Rated Clinics</p>
            <p className="text-left text-lg">
              GrinGo only partners with pre-vetted, top-tier dental clinics,
              with proven quality and patient satisfaction
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <Plane color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              Seamless Travel Logistics
            </p>
            <p className="text-left text-lg">
              GrinGo handles your flights, accommodation, tours logistics for a
              smooth, hassle-free journey
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <HeartHandshake color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              Support From Start to Finish
            </p>
            <p className="text-left text-lg">
              A personalized Patient Advocate will come with you to every
              dentist appointment
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <DollarSign color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              US Payment Processing
            </p>
            <p className="text-left text-lg">
              Pay through our secure, US-based platform
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <Medal color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">Top-Rated Clinics</p>
            <p className="text-left text-lg">
              GrinGo only partners with pre-vetted, top-tier dental clinics,
              with proven quality and patient satisfaction
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide-image swiper-carousel-animate-opacity bg-gringoPrimaryDark" />
          <div className="swiper-slide-content swiper-carousel-animate-opacity">
            <Plane color="#FFFFFF" className="h-12 w-12 mb-2" />
            <p className="text-left text-2xl font-bold">
              Seamless Travel Logistics
            </p>
            <p className="text-left text-lg">
              GrinGo handles your flights, accommodation, tours logistics for a
              smooth, hassle-free journey
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
