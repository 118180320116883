import React, { ReactNode } from "react";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className="w-full h-full min-h-screen flex flex-col">
      <NavBar />
      <div className="flex flex-col items-center sm:items-start flex-grow">
        <main className="w-full h-full">{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
