import Section from "./Section";
import gringoLogo from "../assets/GrinGoFooter.png";

export const Footer = () => {
  return (
    <footer className="bg-gringoPrimaryDark bottom-0 left-0 w-full px-6 py-12 text-center">
      <Section>
        <p className="text-sm text-white text-start">+1 (617) 237-0201</p>
        <p className="text-sm text-white text-start">contact@gringo.care</p>

        <p className="mt-5 text-sm text-white text-start">
          1360 W University Ave, Gainesville, FL 32603
        </p>
        <p className="text-sm text-white text-start">
          Av. Paseo de la República 6171, Miraflores 15047, Peru
        </p>
        <p className="mt-5 text-sm text-white text-start">
          © 2023 GrinGo. All rights reserved.
        </p>

        <img src={gringoLogo} alt="GringoLogo" className="mt-5 w-44 h-auto" />
      </Section>
    </footer>
  );
};
