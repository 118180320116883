/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import { DentistFact, Service } from "../../localDB/types";
import Section from "../Section";
import { MobileCarousel } from "./MobileCarousel";
import Layout from "../Layout";
import { useParams } from "react-router-dom";
import useClinicDetails from "../../hooks/useClinicDetails";
import { SyncLoader } from "react-spinners";
import { SlideshowModal } from "./SlideshowModal";
import ServicesCostCalculator from "./ServicesCostCalculator";
import { Card, Row } from "@nextui-org/react";
import { GraduationCap, Info } from "lucide-react";
import { Divider } from "../Divider";
import StyledMap from "../StyledMap";
import useCityDetails from "../../hooks/useCityDetails";

export type ServiceWithQuantity = Service & { quantity: number };

const ClinicDetails = () => {
  const [loading, setLoading] = useState(true);
  const [visibleSlideshowModal, setVisibleSlideshowModal] =
    useState<boolean>(false);
  const { id } = useParams();

  const { clinic, status, isLoading } = useClinicDetails(id ?? "");
  const { city } = useCityDetails("mock");

  const [services, setServices] = useState<ServiceWithQuantity[]>([]);

  const imageUrls: string[] = [];
  imageUrls.push(
    `https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/featured.jpg`,
    `https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/photo2.jpg`,
    `https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/photo3.jpg`,
    `https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/photo4.jpg`,
    `https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/photo5.jpg`
  );

  useEffect(() => {
    if (clinic !== undefined) {
      setLoading(false);
    }
    setServices(
      city?.Services.map((service) => ({ ...service, quantity: 0 })) ?? []
    );
  }, [city?.Services, clinic]);

  const updateServices = (index: number, quantity: number) => {
    setServices((prevServices) =>
      prevServices.map((service, idx) => {
        if (index === idx) {
          return {
            ...service,
            quantity: quantity,
          };
        }
        return service;
      })
    );
  };

  const factRow = (fact: DentistFact) => {
    return (
      <>
        <Row className="pb-2">
          {fact.icon === "study" ? (
            <GraduationCap
              aria-label="icon"
              className="mr-1 "
              color="#828282"
            />
          ) : (
            <Info aria-label="icon" className="mr-1 " color="#828282" />
          )}
          <p className="text-base font-normal">{fact.fact}</p>
        </Row>
      </>
    );
  };

  return (
    <Layout>
      <div
        className="md:hidden"
        onClick={() => {
          setVisibleSlideshowModal(true);
        }}
      >
        <MobileCarousel images={imageUrls}></MobileCarousel>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <SyncLoader color="#0068d3" speedMultiplier={0.7} />
        </div>
      ) : (
        <>
          <Section className="md:pt-10">
            <h1 className="font-semibold text-3xl text-gringoPrimaryDark mt-3 md:mt-0">
              {clinic?.name}
            </h1>
            <h2 className="text-base font-normal mt-1 text-gringoPrimaryDark">
              {clinic?.subtitle ?? ""}
            </h2>
            <h2 className="text-base font-normal mt-1 text-gringoPrimaryDark">
              {`${clinic?.city.name}, ${clinic?.city?.Country?.name}` ?? ""}
            </h2>
            <div
              className="hidden md:block hover:cursor-pointer"
              onClick={() => {
                setVisibleSlideshowModal(true);
              }}
            >
              <div className="grid grid-areas-layout overflow-hidden gap-2 mt-5">
                <>
                  <div className="grid-in-photoOne h-96 relative">
                    <img
                      src={imageUrls[0]}
                      className="rounded-l-lg  bg-slate-200 object-cover  w-full h-full absolute"
                    />
                  </div>
                  <div className="grid-in-photoTwo h-full relative">
                    <img
                      src={imageUrls[1]}
                      className=" bg-slate-200 object-cover w-full h-full absolute"
                    />
                  </div>
                  <div className="grid-in-photoThree h-full relative">
                    <img
                      src={imageUrls[2]}
                      className="rounded-tr-lg  bg-slate-200 object-cover w-full h-full absolute"
                    />
                  </div>
                  <div className="grid-in-photoFour h-full relative">
                    <img
                      src={imageUrls[3]}
                      className=" bg-slate-200 object-cover w-full h-full absolute"
                    />
                  </div>
                  <div className="grid-in-photoFive h-full relative bg-black-600">
                    <img
                      src={imageUrls[4]}
                      className="rounded-r-lg bg-slate-200 object-cover w-full h-full absolute"
                    />
                  </div>
                </>
              </div>
            </div>

            {/* <Divider className="" /> */}

            <div className="flex flex-col md:flex-row gap-10">
              <div className="w-full md:w-2/3">
                <h2 className="font-semibold text-xl text-gringoPrimary mt-10">{`Calculator: GrinGo prices in ${clinic?.city?.Country?.name}`}</h2>
                <ServicesCostCalculator
                  services={services}
                  onServicesChange={updateServices}
                />
              </div>
              <div className="w-full h-max-full md:w-1/2">
                {/* <h2 className="font-semibold text-gringoPrimary text-xl mt-5 md:mt-10">{`Meet your dentist`}</h2> */}
                <Card
                  css={{
                    py: "$10",
                    px: "$10",
                    marginTop: "$15",
                    h: "stretch",
                  }}
                >
                  <p className="text-base italic font-normal text-center pb-5 whitespace-pre-line">
                    {clinic?.description}
                  </p>
                  {/* <div className="flex flex-col items-center">
                    <img
                      height={"170px"}
                      width={"170px"}
                      src={`https://gringo-public-assets.s3.amazonaws.com/clinic/${id}/images/dentist.png`}
                      alt="Dentist"
                      className="rounded-full bg-slate-200 object-cover mt-3 border-gray-200 border-2"
                    />
                    <h1 className="font-semibold text-xl pt-10 pb-2">
                      {`${clinic?.dentists[0].firstName} ${clinic?.dentists[0].lastName}`}
                    </h1>
                    <p className="text-base italic font-normal text-center pb-5">
                      {clinic?.dentists[0].description}
                    </p>
                    <div>
                      {clinic?.dentists[0].facts.map((fact) => factRow(fact))}
                    </div>
                  </div> */}
                </Card>
              </div>
            </div>

            <Divider className="my-10" />
            <h2 className="font-semibold text-xl text-gringoPrimary mb-5">{`What GrinGos are saying`}</h2>
            <div className="grid md:grid-cols-3 gap-5">
              {clinic?.testimonials.map((testimonial) => (
                <Card css={{ py: "$10", px: "$10" }}>
                  <p className="text-md font-semibold">{testimonial.name}</p>
                  <p className="text-base text-slate-500 mb-1">
                    {testimonial.date}
                  </p>
                  <p className="text-base ">{testimonial.text}</p>
                </Card>
              ))}
            </div>
            <Divider className="mt-5" />
            <div className="w-full h-56 md:h-96 mb-20">
              <h2 className="font-semibold text-xl text-gringoPrimary mt-10 mb-5">
                {"Where you'll be"}
              </h2>
              <StyledMap
                center={{
                  lat: clinic?.latitude ?? -12.1319502,
                  lng: clinic?.longitude ?? -77.0330898,
                }}
                containerStyle={{ width: "100%", height: "100%" }}
              />
            </div>
          </Section>
          <SlideshowModal
            visible={visibleSlideshowModal}
            onClose={() => setVisibleSlideshowModal(false)}
            images={imageUrls}
          />
        </>
      )}
    </Layout>
  );
};

export default ClinicDetails;
