// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ReservationState = {
  "CONFIRMING_DETAILS": "CONFIRMING_DETAILS",
  "PENDING_PAYMENT": "PENDING_PAYMENT",
  "PLANNING_TRIP": "PLANNING_TRIP",
  "TRIP_IN_PROGRESS": "TRIP_IN_PROGRESS",
  "COMPLETED": "COMPLETED",
  "PENDING_GRINGO_FEE": "PENDING_GRINGO_FEE",
  "CANCELED": "CANCELED"
};

const { Users, Message, Conversation, Reservation, Country, City, Service, Clinic, ServiceIdWithQuantity } = initSchema(schema);

export {
  Users,
  Message,
  Conversation,
  Reservation,
  Country,
  City,
  Service,
  Clinic,
  ReservationState,
  ServiceIdWithQuantity
};