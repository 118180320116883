import { useEffect, useState } from "react";
import Layout from "../Layout";
import Section from "../Section";
import { SyncLoader } from "react-spinners";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useQuery } from "@tanstack/react-query";
import { Button, Card } from "@nextui-org/react";
import { Divider } from "../Divider";
import { useLocation } from "react-router-dom";
import useUserDetails from "../../hooks/useUserDetails";
import { SignInButton, useUser } from "@clerk/clerk-react";
import { ServiceIdWithQuantity } from "../../localDB/types";
import currency from "currency.js";
import { getAllOnX, getDentalImplant } from "../Quote/utils";
import moment from "moment";
import { ArrowRight } from "lucide-react";

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  throw new Error("Missing Stripe Publishable Key");
}
const stripePubKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripePubKey);

const StartReservationScreen = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const service = params.get("service");
  // If full-mouth implants
  const upper = params.get("upper");
  const lower = params.get("lower");

  //
  const implantQuantity = params.get("qty");

  const cost = params.get("cost");
  const firstAppointmentDate = params.get("startDate");

  const authenticatedUser = useUser();
  const authenticatedUserId = authenticatedUser.user?.id;
  const userDetails = useUserDetails(authenticatedUserId ?? "");

  const createPaymentIntentAndPendingReservation = async (
    amount: number,
    services: ServiceIdWithQuantity[],
    startDate: string,
    totalCost: number,
    patientId?: string,
    stripeCustomerId?: string
  ) => {
    try {
      if (!amount || !stripeCustomerId || !patientId) return;
      const res = await fetch(
        "https://qcvrc76p48.execute-api.us-east-1.amazonaws.com/default/stripePay",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amount: amount * 100,
            services,
            startDate,
            totalCost,
            patientId,
            stripeCustomerId,
          }),
        }
      );
      const data = await res.json();
      return data.clientSecret;
    } catch (error) {
      console.log("Error fetching client secret:", error);
    }
  };

  const parsedServices: ServiceIdWithQuantity[] = [];
  const lowerAllOnX = getAllOnX(lower);
  const upperAllOnX = getAllOnX(upper);
  if (lowerAllOnX.name !== "undefined") {
    parsedServices.push(lowerAllOnX);
  }
  if (upperAllOnX.name !== "undefined") {
    parsedServices.push(upperAllOnX);
  }
  if (implantQuantity && implantQuantity !== "null") {
    parsedServices.push(getDentalImplant(Number(implantQuantity)));
  }

  const {
    status: paymentIntentStatus,
    data: stripeClientSecret,
    refetch,
  } = useQuery({
    enabled: Boolean(userDetails?.stripeCustomerId) && Boolean(userDetails?.id),
    queryKey: ["clientSecret"],
    queryFn: () =>
      createPaymentIntentAndPendingReservation(
        99,
        parsedServices,
        firstAppointmentDate ?? "TBD",
        Number(cost) ?? 0,
        userDetails?.id,
        userDetails?.stripeCustomerId
      ),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity, // The data remains fresh indefinitely
    cacheTime: Infinity, // The cache remains indefinitely
  });

  // Trigger refetch when user signs in
  useEffect(() => {
    refetch();
  }, [authenticatedUser.isSignedIn, refetch]);

  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret: stripeClientSecret,
    appearance,
  };

  const priceDetailsCard = () => {
    return (
      <>
        <Card css={{ py: "$10", px: "$10", marginBottom: "$10" }}>
          <span className="text-lg font-semibold pb-5">{`Due now`}</span>
          <div className="flex flex-row items-center">
            <span className="text-sm pb-2">{`Security deposit (fully refundable)`}</span>
            <span className="text-sm ml-auto">{`$99`}</span>
          </div>
          <Divider className="mb-5" />
          <div className="flex flex-row items-center">
            <span className="text-md font-semibold pb-2">{`Total`}</span>
            <span className="text-md font-semibold pb-2 ml-auto">{`$99`}</span>
          </div>
        </Card>
      </>
    );
  };

  const payWithSection = () => {
    return (
      <>
        <span className="font-semibold text-xl pb-2">Pay with</span>
        <div className="py-5">
          {paymentIntentStatus === "success" ? (
            <Elements
              options={options}
              stripe={stripePromise}
              key={stripeClientSecret}
            >
              <CheckoutForm email={userDetails?.email} />
            </Elements>
          ) : (
            <div className="flex flex-col gap-3 items-center">
              <div className="rounded-3xl p-5 bg-[#eceef0] w-full">
                <div className="flex justify-center items-center w-full h-56">
                  <SyncLoader color="#0e0e40" speedMultiplier={0.7} />
                </div>
              </div>
              <Button
                auto
                disabled
                shadow
                color="primary"
                iconRight={<ArrowRight className="h-4 w-4" />}
                css={{ color: "$white", fontWeight: "$semibold", width: "50%" }}
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <Layout>
      <Section>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <SyncLoader color="#0068d3" speedMultiplier={0.7} />
          </div>
        ) : (
          <>
            <h1 className="font-bold text-3xl md:text-3xl  text-gringoPrimaryDark mt-10 md:mt-16">
              Pay security deposit and lock rate
            </h1>
            <div className="flex flex-col md:flex-row w-full mt-10 gap-10">
              <div className="flex flex-col md:w-1/2">
                <span className="text-md font-semibold text-gringoPrimaryDark">
                  Estimated first appointment date:
                </span>
                <span className="text-sm mb-3 text-gringoPrimaryDark">
                  {moment(firstAppointmentDate)?.format("MMMM DD, YYYY")}
                </span>
                {service === "fmi" ? (
                  <>
                    <span className="text-md font-semibold text-gringoPrimaryDark">
                      Dental service:
                    </span>
                    {lower && lower !== "null" ? (
                      <span className="text-sm text-gringoPrimaryDark">
                        {lower !== "0" ? `Lower: All-on-${lower}` : undefined}
                      </span>
                    ) : undefined}
                    {upper && upper !== "null" ? (
                      <span className="text-sm text-gringoPrimaryDark">
                        {upper !== "0" ? `Upper: All-on-${upper}` : undefined}
                      </span>
                    ) : undefined}
                  </>
                ) : undefined}
                {service === "di" ? (
                  <>
                    <span className="text-md font-semibold text-gringoPrimaryDark">
                      Dental service:
                    </span>
                    {implantQuantity && implantQuantity !== "null" ? (
                      <span className="text-sm text-gringoPrimaryDark">
                        {`Dental Implant x ${implantQuantity}`}
                      </span>
                    ) : undefined}
                  </>
                ) : undefined}
                <span className="text-md font-semibold mt-3 text-gringoPrimaryDark">
                  Total cost:
                </span>
                <span className="text-sm mb-3">
                  {currency(cost ?? 0, { precision: 2 }).format()}
                </span>
                <div className="mt-5 block md:hidden">{priceDetailsCard()}</div>
                <Divider className="mb-3 md:w-1/2 self-center" />
                {authenticatedUser.isSignedIn ? (
                  payWithSection()
                ) : (
                  <>
                    <SignInButton
                      mode="modal"
                      redirectUrl={`/start-reservation/?service=fmi&lower=${lower}&upper=${upper}&startDate=${firstAppointmentDate}&cost=${cost}`}
                    >
                      <Button
                        size="lg"
                        color={"primary"}
                        rounded
                        shadow
                        css={{
                          width: "50%",
                          alignSelf: "center",
                          marginTop: "$10",
                          marginBottom: "$13",
                          color: "$white",
                          fontWeight: "$semibold",
                        }}
                      >
                        Sign In to Reserve
                      </Button>
                    </SignInButton>
                  </>
                )}
              </div>
              <div className="flex flex-col md:w-1/2 hidden md:block">
                {priceDetailsCard()}
              </div>
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default StartReservationScreen;
