import Layout from "../Layout";
import Section from "../Section";
import { EmbarkSection } from "../EmbarkSection";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calcServiceRange,
  getAllOnX,
  getDentalImplant,
  getFirstAppointmentDate,
} from "./utils";
import moment from "moment";
import { Divider } from "../Divider";
import currency from "currency.js";
import { Button } from "@nextui-org/react";
import { animated, config, useSpring } from "@react-spring/web";
import { Car, Heart, Hotel, Printer, Smile } from "lucide-react";
import { BlueCard } from "../Home/BlueCard";
import ReactGA from "react-ga4";
import { CallMeNowButton } from "./CallMeNowButton";
import {
  QuoteServiceCostSection,
  renderServicePriceRow,
} from "./QuoteServiceCostSection";

export type serviceType = "fmi" | "di";
export const HOTEL_RATE_PER_NIGHT: number = 65;

export const QuoteScreen = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const service = params.get("service");
  const name = params.get("name");
  const date = params.get("date");

  // if dental implant
  const diQuantity = params.get("qty");

  // if ful-mouth-implants
  const upper = params.get("upper");
  const lower = params.get("lower");

  // TO-DO: Should load services right away using this variables and pass services to all the other components

  const totalCost =
    getAllOnX(lower).serviceCost +
    getAllOnX(upper).serviceCost +
    getDentalImplant(Number(diQuantity)).serviceCost +
    7 * HOTEL_RATE_PER_NIGHT;

  const animationProps = useSpring({
    transform: "translateY(0%)",
    config: config.default,
  });

  const getNavigationUrl = (service: serviceType) => {
    let url = `/start-reservation/?service=${service}&startDate=${getFirstAppointmentDate(
      date
    )?.format("YYYY-MM-DD")}&cost=${totalCost}`;
    if (service === "fmi") {
      url += `&lower=${lower}&upper=${upper}`;
    } else if (service === "di") {
      url += `&qty=${diQuantity}`;
    }
    return url;
  };

  const GeneralInfo = () => {
    return (
      <>
        <h1 className="text-2xl md:text-3xl text-center font-semibold text-gringoPrimary ">
          {(service as serviceType) === "fmi" ? "Full Mouth " : "Titanium "}
          Implants Quote
        </h1>
        <h2 className="text-2xl md:text-3xl text-center font-semibold font-berlingske text-gringoPrimary ">
          {`Prepared for: ${name}`}
        </h2>
        <button
          onClick={() => window.print()}
          className=" text-gray-400 font-semibold mt-2 rounded-full flex flex-row items-center self-center"
        >
          Print <Printer className="h-4 w-4 ml-2" />
        </button>
        <div>
          <p className="mt-5 pb-2 text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
            Valid until: {moment().add(15, "days").format("MMMM DD, YYYY")}
          </p>
          <p className="pb-2 text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
            First trip: {calcServiceRange(date)}
          </p>
          <p className="pb-2 text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
            Second trip: {calcServiceRange(date, 6)}
          </p>
          <a
            href="/clinic/7ab6e593-6181-4189-a125-c124c0a5e885"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="pb-2 text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
              Dental Clinic:{" "}
              <span className="text-gringoPrimary">
                German Dental Clinic (Lima, Peru)
              </span>
            </p>
          </a>
          <a
            href="https://goo.gl/maps/AiP3Ar7fee45dawu9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="pb-2 text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
              Hotel:{" "}
              <span className="text-gringoPrimary">Inkari Luxury (4-Star)</span>
            </p>
          </a>
        </div>
      </>
    );
  };

  const TotalCost = () => {
    return (
      <>
        <div className="flex flex-row">
          <p className="pb-2 font-bold text-center md:text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark">
            Total
          </p>
          <p className="pb-2 font-bold text-center md:text-left text-lg leading-relaxed md:text-xl text-gringoPrimaryDark ml-auto">
            {currency(totalCost, {
              precision: 0,
            }).format()}
          </p>
        </div>
        <Divider className="mb-5 border-gray-400" />
        <p className="pb-2 text-start text-md leading-relaxed md:text-md text-gringoPrimaryDark">
          ¹ Payment is to be made through GrinGo's U.S.-based secure payment
          platform in two parts:
          <span className="font-bold">
            {" "}
            {currency(totalCost / 2, { precision: 0 }).format()}{" "}
          </span>
          before the first trip,
          <span className="font-bold">
            {" "}
            {currency(totalCost / 2, { precision: 0 }).format()}{" "}
          </span>
          before the second trip.
        </p>
        <p className="pb-2 text-start text-md leading-relaxed md:text-md text-gringoPrimaryDark">
          ² Dates are estimates.
        </p>
        <p className="pb-2 text-start text-md leading-relaxed md:text-md text-gringoPrimaryDark">
          ³ Flights are not included.
        </p>
        <p className="pb-2 text-start text-md leading-relaxed md:text-md text-gringoPrimaryDark">
          ⁴ Accommodation included for the first trip.
        </p>
      </>
    );
  };

  const FooterButtons = () => {
    return (
      <animated.div
        className="w-full h-24 fixed bottom-0 bg-transparent shadow-lg flex flex-row items-center justify-center"
        style={animationProps}
      >
        <div className="flex flex-col items-center">
          <Button
            shadow
            onClick={() => {
              ReactGA.event({
                category: "Pay & Lock Rate Button",
                action: `pay_lock_rate_button_click`,
              });
              navigate(getNavigationUrl(service as serviceType));
            }}
            color={"primary"}
            rounded
            size="lg"
            css={{ color: "$white", fontWeight: "$semibold" }}
          >
            Lock Rate for $99 (Refundable)
          </Button>
          <CallMeNowButton name={name ?? "undefined"} />
        </div>
      </animated.div>
    );
  };

  const IncludedSection = () => {
    return (
      <>
        <h1 className="text-2xl md:text-2xl text-left font-bold text-gringoPrimary mt-10 md:mt-16 mb-3">
          Included
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-10 mt-10">
          <BlueCard
            title="Full dental treatment"
            description="Free tooth extractions and CBCT scans"
            IconComponent={Smile}
          />
          <BlueCard
            title="Accommodation"
            description="Great location, 4-star hotel for 7 nights"
            IconComponent={Hotel}
          />
          <BlueCard
            title="Patient Advocate"
            description="Bilingual dental professional who will come you to every appointment"
            IconComponent={Heart}
          />
          <BlueCard
            title="Personal Driver"
            description="Airport and appointment pickup and drop-off"
            IconComponent={Car}
          />
        </div>
      </>
    );
  };

  const servicePlanSection = (serviceType: serviceType) => {
    switch (serviceType) {
      case "fmi":
        return (
          <>
            <>
              <p className="text-left font-semibold text-lg md:text-xl text-gringoPrimaryDark">
                Phase 1 - Implant Placement:
              </p>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                7 days at destination
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Evaluation
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Full Mouth Implant surgery
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Provisional Prosthesis placement
              </li>
            </>
            <>
              <p className="mt-5 text-left font-semibold text-lg md:text-xl text-gringoPrimaryDark">
                Phase 2 (4-6 months after Phase 1) - Hybrid Prothesis Placement:
              </p>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                7 days at destination
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Evaluation
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Full Mouth Implant surgery
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Provisional Prosthesis placement
              </li>
            </>
          </>
        );
      case "di":
        return (
          <>
            <>
              <p className="text-left font-semibold text-lg md:text-xl text-gringoPrimaryDark">
                Phase 1 - Titanium Implant Placement:
              </p>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                7 days at destination
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Evaluation
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Implant surgery
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Provisional crown placement
              </li>
            </>
            <>
              <p className="mt-5 text-left font-semibold text-lg md:text-xl text-gringoPrimaryDark">
                Phase 2 (4-6 months after Phase 1) - Crown Placement:
              </p>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                5 days at destination
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Evaluation
              </li>
              <li className="my-1 text-left text-lg md:text-xl text-gringoPrimaryDark">
                Porcelain Crown placement
              </li>
            </>
          </>
        );
    }
  };

  return (
    <Layout>
      <Section>
        <div className="bg-gringoGray my-10 p-7 rounded-[50px]">
          <div className="flex flex-col">
            <GeneralInfo />
            <Divider className="mb-5 border-gray-400" />
            <QuoteServiceCostSection
              serviceType={service as serviceType}
              fmiLower={lower ?? "0"}
              fmiUpper={upper ?? "0"}
              diQuantity={diQuantity ?? "0"}
            />

            <Divider className="mb-5 border-gray-300" />
          </div>
          <TotalCost />
        </div>
        <IncludedSection />
        <h1 className="text-2xl md:text-2xl text-left font-bold text-gringoPrimary mt-14 mb-3">
          Service Plan
        </h1>
        {servicePlanSection(service as serviceType)}
      </Section>
      <EmbarkSection />
      <FooterButtons />
    </Layout>
  );
};
