import { Image } from "@nextui-org/react";
import { Clinic as ClinicModel } from "../../localDB/types";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ClinicCardProps {
  clinic?: ClinicModel;
  image?: string;
}

export const ClinicCard = ({ clinic }: ClinicCardProps) => {
  const [image, setImage] = useState<string>("");

  useEffect(() => {
    const getFeaturedImage = async () => {
      setImage(
        `https://gringo-public-assets.s3.amazonaws.com/clinic/${clinic?.id}/images/featured.jpg`
      );
    };
    getFeaturedImage();
  }, [clinic?.id]);
  return (
    <Link to={`/clinic/${clinic?.id}`}>
      <div className="mt-6 flex flex-col items-start cursor-pointer">
        <div className="items-center">
          <div className="relative">
            {image !== "" ? (
              <Image
                src={image}
                alt="clinic image"
                className="rounded-2xl shadow-xl"
                width={350}
                height={200}
                objectFit="cover"
                showSkeleton
              />
            ) : undefined}
          </div>

          <div className="mt-4 flex flex-col items-center justify-center ">
            <h2 className="mt-1 text-xl text-center font-semibold text-gringoPrimaryDark">
              {clinic?.name}
            </h2>
            <p className="mt-1 text-sm text-gringoPrimaryDark">
              {clinic?.subtitle}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
