import { ClinicCard } from "../Clinic/ClinicCard";
import { Clinic } from "../../localDB/types";
import { Divider } from "../Divider";

interface HomeCitiesProps {
  clinics?: Clinic[];
}
export const HomeCities = ({ clinics }: HomeCitiesProps) => {
  return (
    <>
      <div className="pt-5">
        <h2 className="text-3xl md:text-4xl text-center font-bold text-gringoPrimaryDark mt-16 ">
          Meet our Partners Abroad
        </h2>
        {clinics ? (
          <>
            <div className="flex flex-col items-center md:flex-row gap-5">
              <ClinicCard clinic={clinics[0]} />
              <ClinicCard clinic={clinics[1]} />
              <ClinicCard clinic={clinics[2]} />
            </div>
          </>
        ) : undefined}
      </div>
      {/* <Divider className="pt-10" />
      <div className="">
        <h2 className="text-xl font-semibold pt-10 text-gringoPrimary font-berlingske">
          Coming soon to
        </h2>
        <ul>
          <h2 className="text-lg font-normal text-gringoPrimaryDark">
            Medellin | Mexico City | Buenos Aires | Rio de Janeiro
          </h2>
        </ul>
      </div> */}
    </>
  );
};
