import { useState } from "react";

interface CallMeNowButtonProps {
  name: string;
}
export const CallMeNowButton = ({ name }: CallMeNowButtonProps) => {
  const [pressed, setPressed] = useState(false);

  const sendSlackNotification = async () => {
    try {
      const response = await fetch(
        "https://quw3wettxi.execute-api.us-east-1.amazonaws.com/default/callMeButtonSlack",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: name,
          }),
        }
      );

      if (response.ok) {
        // console.log("Notification sent via Lambda to Slack!");
        setPressed(true);
      } else {
        // console.error("Failed to send notification via Lambda.");
      }
    } catch (error) {
      //   console.error("There was an error sending the notification:", error);
    }
  };

  return (
    <div className="flex flex-row items-center">
      <button
        onClick={sendSlackNotification}
        className="text-sm font-bold my-3 px-3 bg-gringoPrimaryDark rounded-xl
  p-1 text-white"
      >
        {pressed ? (
          <span>We'll call you in a second</span>
        ) : (
          <span>CALL ME NOW</span>
        )}
      </button>
    </div>
  );
};
