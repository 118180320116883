import { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import Section from "./Section";
import { Button } from "@nextui-org/react";
import { HomeCities } from "./Home/HomeCities";
import { Divider } from "./Divider";
import { listClinics } from "../graphql/queries";
import { API, GraphQLQuery } from "@aws-amplify/api";
import { ListClinicsQuery } from "../API";
import { Clinic } from "../localDB/types";
import ServicesCostCalculator from "./Clinic/ServicesCostCalculator";
import { ServiceWithQuantity } from "./Clinic/ClinicDetails";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import useCityDetails from "../hooks/useCityDetails";
import { PopupButton } from "@typeform/embed-react";
// import { animated, config, useSpring } from "@react-spring/web";
import ReactGA from "react-ga4";

// Import Swiper styles
import ImageChanger from "./ImageChanger";
import { HomeSteps } from "./Home/HomeSteps";
import { EmbarkSection } from "./EmbarkSection";
import SwiperHome from "./SwiperCarousel/SwiperHome";
import moment from "moment";

// import { SelectService } from "./Home/SelectService";

const listClinicsByLimaCityId = () =>
  API.graphql<GraphQLQuery<ListClinicsQuery>>({
    query: listClinics,
    variables: {
      filter: {
        clinicCityId: { eq: "3c9389f3-64f5-4b16-bf03-4cd2b3e3eff0" },
      },
    },
  });

const Home = () => {
  const navigate = useNavigate();
  // const authenticatedUser = useUser();
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [services, setServices] = useState<ServiceWithQuantity[]>([]);
  const { city } = useCityDetails("hardcoded_mock");
  const [visibleReservationModal, setVisibleReservationModal] =
    useState<boolean>(false);
  // const [hasScrolled, setHasScrolled] = useState<boolean>(false);
  const formRef = useRef<any>(null);
  const floatingFormRef = useRef<any>(null);

  // const animationProps = useSpring({
  //   transform: hasScrolled ? "translateY(0%)" : "translateY(100%)",
  //   config: config.default,
  // });

  const handleServicesChanged = (index: number, quantity: number) => {
    ReactGA.event({
      category: "Calculator Button",
      action: `calculator_button_click_${index}`,
    });

    setServices((prevServices) =>
      prevServices.map((service, idx) => {
        if (index === idx) {
          return {
            ...service,
            quantity: quantity,
          };
        }
        return service;
      })
    );
  };

  const { data: clinicsData } = useQuery(
    ["listClinicsByLimaCityId"],
    () => listClinicsByLimaCityId(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: Infinity,
    }
  );

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       // Change the value as needed
  //       setHasScrolled(true);
  //     } else {
  //       setHasScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    setClinics(clinicsData?.data?.listClinics?.items as unknown as Clinic[]);
  }, [clinicsData?.data?.listClinics?.items]);

  useEffect(() => {
    if (city?.Services) {
      setServices(
        city.Services.map((service) => ({
          ...service,
          quantity: 0,
        })) as ServiceWithQuantity[]
      );
    }
  }, [city?.Services]);

  const handleGetFreeQuoteClicked = (type: "static" | "floating") => {
    type === "floating"
      ? floatingFormRef.current?.open()
      : formRef.current?.open();
    ReactGA.event({
      category: "Get Free Quote Button",
      action: `free_quote_click_${type}`,
    });
  };

  return (
    <Layout>
      <div className="h-60 overflow-hidden md:hidden">
        <ImageChanger className="top-0 w-full h-full object-cover object-top md:hidden" />
      </div>
      <div className="flex flex-col md:flex-row gap-7 pt-5 px-2">
        <div className="flex w-full flex-row justify-around md:w-1/2 md:flex-col px-10 pb-5 md:p-0">
          <div className="md:pl-36 bgblue-400">
            <h1 className="md:mt-5 text-center md:text-left text-4xl font-semibold md:text-5xl text-gringoPrimary">
              Get dental care in Latin America. Skip the hassle.
            </h1>
            <p className="mt-5 mb-10 text-center md:text-left text-xl leading-relaxed md:text-2xl text-gringoPrimaryDark">
              We make dental travel easy – savings, quality, security, and every
              detail covered.
            </p>
            <div className="bgred-400 flex justify-center items-center hidden md:block md:justify-start md:items-start">
              <PopupButton id="HubYNANx" style={{ fontSize: 20 }} ref={formRef}>
                <div className="flex flex-col gap-3 items-center">
                  <Button
                    shadow
                    onClick={() => {
                      handleGetFreeQuoteClicked("static");
                    }}
                    rounded
                    size="lg"
                    css={{ color: "$white", fontWeight: "$semibold" }}
                  >
                    Get a Free Quote
                  </Button>
                  <span className="text-sm mt-2 text-gringoPrimary">
                    Takes 15 seconds
                  </span>
                </div>
              </PopupButton>
            </div>
          </div>
        </div>
        <div className="flex hidden w-full md:block md:w-1/2">
          <div className="ml-auto w-full">
            <ImageChanger className="rounded-full  bg-slate-200 object-cover" />
          </div>
        </div>
      </div>
      <Section>
        {clinics ? (
          <div className="">
            <h2 className="text-3xl md:text-4xl text-center font-bold text-gringoPrimaryDark mt-16 ">
              Calculate how much you'll save with GrinGo
            </h2>
            <h3 className="text-lg md:text-xl text-center font-bold text-gringoPrimaryDark">
              Prices as of {moment().format("MMMM DD, YYYY")}
            </h3>
            <div className="bg-gringoGray p-10 rounded-[50px] mt-10 flex flex-col">
              <ServicesCostCalculator
                services={services}
                onServicesChange={handleServicesChanged}
              />
              <Divider className="mb-5" />
              <Button
                size="lg"
                color={"primary"}
                rounded
                iconRight={<ArrowRight className="h-4 w-4" />}
                onClick={() => handleGetFreeQuoteClicked("static")}
                css={{
                  width: "50%",
                  alignSelf: "center",
                  marginTop: "$10",
                  color: "$white",
                  fontWeight: "$semibold",
                }}
              >
                Start Reservation
              </Button>
              {/* <ReservationModal
                visible={visibleReservationModal}
                onClose={() => setVisibleReservationModal(false)}
                services={services.filter((service) => service.quantity !== 0)}
                onPressContinue={(date) => {
                  navigate("/start-reservation", {
                    state: {
                      services: services.filter(
                        (service) => service.quantity !== 0
                      ),
                      startDate: date,
                    },
                  });
                }}
              /> */}
            </div>
          </div>
        ) : undefined}
      </Section>

      <div className="bg-gringoPrimary mt-10 py-10 ">
        <h2 className="text-3xl md:text-4xl pb-3 text-center font-bold text-gringoPrimaryDark">
          Trust, Save, & Explore
        </h2>
        <SwiperHome></SwiperHome>
      </div>

      <h2 className="text-3xl md:text-4xl text-center font-bold text-gringoPrimaryDark mt-16 pb-5">
        Simple steps, bigger smiles
      </h2>
      <HomeSteps />

      <Section>
        <HomeCities clinics={clinics} />
      </Section>

      <EmbarkSection />

      <div
        className="w-full h-24 fixed bottom-0 bg-transparent shadow-lg md:hidden flex items-center justify-center"
        // style={animationProps}
      >
        <PopupButton
          id="HubYNANx"
          style={{ fontSize: 20 }}
          ref={floatingFormRef}
        >
          <Button
            shadow
            onClick={() => handleGetFreeQuoteClicked("floating")}
            rounded
            size="lg"
            css={{ color: "$white", fontWeight: "$semibold" }}
          >
            Get a Free Quote
          </Button>
          <span className="text-sm mt-2 text-gringoPrimary bg-white rounded-xl px-2 py-1">
            Takes 15 seconds
          </span>
        </PopupButton>
      </div>
    </Layout>
  );
};

export default Home;
