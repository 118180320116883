import { Modal, Text } from "@nextui-org/react";
import { useState } from "react";
import { ServiceWithQuantity } from "./ClinicDetails";
import { useNavigate } from "react-router-dom";
import { SlideshowCarousel } from "./SlideshowCarousel";
import { ArrowLeftCircle } from "lucide-react";

interface SlideshowModalProps {
  visible: boolean;
  onClose: () => void;
  images: string[];
}

export const SlideshowModal: React.FC<SlideshowModalProps> = ({
  visible,
  onClose,
  images,
}) => {
  const [modalContent, setModalContent] = useState(1);
  const navigate = useNavigate();

  const getModalContent = () => {
    switch (modalContent) {
      case 1:
        return <SlideshowCarousel images={images}></SlideshowCarousel>;
      default:
        return null;
    }
  };

  return (
    <Modal
      closeButton
      blur
      open={visible}
      noPadding
      width="800px"
      onClose={() => {
        onClose();
      }}
      className="cursor-default"
    >
      {getModalContent()}
    </Modal>
  );
};
