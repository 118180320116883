import { Collapse, Text } from "@nextui-org/react";
import Layout from "./Layout";
import Section from "./Section";
import { faqGroups } from "./FaqsContent";

const FaqsScreen = () => {
  return (
    <Layout>
      <Section className="">
        <>
          <div className="flex flex-col mt-10">
            {faqGroups.map((faqGroup) => {
              return (
                <div className="pb-10">
                  <div className="text-left pb-5"></div>
                  <h1 className="font-bold text-3xl text-gringoPrimaryDark mb-5">
                    {faqGroup.title}
                  </h1>
                  <Collapse.Group
                    bordered
                    borderWeight={"light"}
                    css={{
                      borderColor: "#efeeeb",
                      fontWeight: "$light",
                      borderRadius: "$xl",
                      backgroundColor: "#efeeeb",
                    }}
                  >
                    {faqGroup.faqs.map((faq) => {
                      return (
                        <Collapse
                          title={faq.question}
                          css={{ fontWeight: "$normal" }}
                        >
                          <Text>{faq.answer}</Text>
                        </Collapse>
                      );
                    })}
                  </Collapse.Group>
                </div>
              );
            })}
          </div>
        </>
      </Section>
    </Layout>
  );
};

export default FaqsScreen;
