import { ArrowDown } from "lucide-react";
import Layout from "../Layout";
import Section from "../Section";
import { StepCard } from "./StepCard";
import textureSky from "../../assets/textures/textureSky.jpg";
import textureSea from "../../assets/textures/textureSea.jpg";
import textureSand from "../../assets/textures/textureSand.jpg";
import { EmbarkSection } from "../EmbarkSection";

const HowItWorksScreen = () => {
  return (
    <Layout>
      <Section>
        <>
          <div className="flex flex-col">
            <h1 className="text-3xl md:text-4xl text-center font-bold text-gringoPrimaryDark mt-10 md:mt-16 mb-10">
              How It Works
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <StepCard
                title={"Explore GrinGo"}
                stepNumber={1}
                description={
                  "Learn about our clinics, prices, and personalized service. Talk to us through our live chat, email, phone, or schedule a meeting with one of our Patient Advocates to solve any questions you have."
                }
              />
              <StepCard
                image={textureSand}
                title={"Book"}
                stepNumber={2}
                description={
                  "When you are ready, pay a $99 security deposit (fully refundable) and start your reservation through our platform. Don’t worry, the first appointment date and services are estimates. We will assign a personalized Patient Advocate to guide you throughout the entire process."
                }
              />
              <StepCard
                title={"Confirm Diagnosis"}
                stepNumber={3}
                description={
                  "Your Patient Advocate and Dentist will help you obtain an accurate diagnosis by evaluating your photos and X-Rays"
                }
              />
              <StepCard
                image={textureSea}
                title={"Plan Your Trip"}
                stepNumber={4}
                description={
                  "Your Patient Advocate will help you schedule all your dentist appointments, determining the best clinic for you, and supporting all your travel logistics through our GrinGo partners."
                }
              />
              <StepCard
                title={"Travel"}
                stepNumber={5}
                description={
                  "Fly to your destination. Your GrinGo Driver and Patient Advocate will welcome you at the airport and take you to your hotel."
                }
              />
              <StepCard
                image={textureSky}
                title={"Get Your Dental Work"}
                stepNumber={6}
                description={
                  "Your GrinGo Driver and your Patient Advocate will be with you at every appointment."
                }
              />
              <StepCard
                title={"Return Home"}
                stepNumber={7}
                description={
                  "After completing your dental work, return home with a new smile!"
                }
              />
            </div>
          </div>
        </>
      </Section>
      <EmbarkSection />
    </Layout>
  );
};

export default HowItWorksScreen;
