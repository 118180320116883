import currency from "currency.js";

interface ServicesPriceCalculationsProps {
  cost: number;
  comparableCost: number;
}

export const ServicesPriceCalculations: React.FC<
  ServicesPriceCalculationsProps
> = ({ cost, comparableCost }) => {
  return (
    <>
      <div className="flex flex-row justify-between mt-5">
        <h2 className="text-lg text-gringoPrimaryDark">
          Estimated cost with GrinGo
        </h2>
        {cost === 0 ? (
          <h2 className="text-lg">-</h2>
        ) : (
          <h2 className="text-lg">
            {currency(cost, { precision: 0 }).format()}
          </h2>
        )}
      </div>
      <div className="flex flex-row justify-between mt-5">
        <h2 className="text-normal text-lg text-gringoPrimaryDark">
          Estimated cost in the USA
        </h2>
        {comparableCost === 0 ? (
          <h2 className="text-lg"> - </h2>
        ) : (
          <h2 className="text-lg">
            {currency(comparableCost, { precision: 0 }).format()}
          </h2>
        )}
      </div>
      <div className="flex flex-row justify-between mt-5">
        <h2 className="text-lg text-gringoPrimary">Savings</h2>
        {comparableCost === 0 ? (
          <h2 className="text-lg"> - </h2>
        ) : (
          <h2 className="text-gringoPrimary text-lg">
            {currency(comparableCost - cost, { precision: 0 }).format()}
          </h2>
        )}
      </div>
    </>
  );
};
