interface LeftAlignedIconWithTextProps {
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}

export const LeftAlignedIconWithText: React.FC<
  LeftAlignedIconWithTextProps
> = ({ IconComponent, title, description }) => (
  <div className="flex flex-col items-start p-10">
    <IconComponent color="#0e0e40" className="h-12 w-12 mb-2" />
    <p className="text-start text-gringoPrimaryDark text-lg mb-1 font-bold md:text-xl font-berlingske">
      {title}
    </p>
    <p className="text-start text-gringoPrimaryDark text-md md:text-lg">
      {description}
    </p>
  </div>
);
