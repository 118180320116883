import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import ClinicsList from "./components/Clinic/ClinicsList";
import ClinicDetails from "./components/Clinic/ClinicDetails";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { ClerkProvider } from "@clerk/clerk-react";
import ReservationList from "./components/Reservation/ReservationList";
import AgentReservationList from "./components/Reservation/AgentReservationList";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PaymentSuccessScreen } from "./components/Reservation/PaymentSuccessScreen";
import StartReservationScreen from "./components/Reservation/StartReservationScreen";
import { NextUIProvider, createTheme } from "@nextui-org/react";
import FaqsScreen from "./components/FaqsScreen";
import HowItWorksScreen from "./components/HowItWorks/HowItWorksScreen";
import WhyGringoScreen from "./components/WhyGringoScreen";
import { IntercomProvider } from "react-use-intercom";
import { IntercomProviderWithUser } from "./components/IntercomProviderWithUser";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import AboutScreen from "./components/AboutScreen";
import PrivacyPolicyScreen from "./components/PrivacyPolicyScreen";
import MarianoScreen from "./components/MarianoScreen";
import { QuoteScreen } from "./components/Quote/QuoteScreen";
import ScrollToTop from "./ScrollToTop";
Amplify.configure(awsconfig);
// Initialize GA4 with your measurement ID
ReactGA.initialize("G-68VC5BZMKR");

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
const queryClient = new QueryClient();

const myTheme = createTheme({
  type: "light",
  theme: {
    colors: {
      // brand colors
      // primaryLight: "$green200",
      // primaryLightHover: "$green300",
      // primaryLightActive: "$green400",
      // primaryLightContrast: "$green600",
      background: "#FFFFFF",
      primary: "#0083f0",
      secondary: "#0e0e40",
      secondaryShadow: "#0e0e40",
      warning: "#fff484",
      // primaryBorder: "$green500",
      // primaryBorderHover: "$green600",
      // primarySolidHover: "$green700",
      // primarySolidContrast: "$white",
      // primaryShadow: "$green500",

      gradient:
        "linear-gradient(112deg, #0083f0 -25%, #0083f0 -10%, #0e0e40 80%)",
    },
    // OVERRIDE LETTER SPACING
    fonts: {
      sans: "NeueMontreal",
      mono: "Andale Mono",
      serif: "Gilda Display",
    },
    letterSpacings: {
      tighter: "0.03em",
      tight: "-0.025em",
      normal: "0.035em",
      wide: "0.035em",
      wider: "0.05em",
      widest: "0.1em",
    },
  },
});

function App() {
  // Get the current location
  const location = useLocation();
  // Use effect to track pageviews on route change
  useEffect(() => {
    // Log pageview with the new path
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <NextUIProvider theme={myTheme}>
      <ClerkProvider publishableKey={clerkPubKey}>
        <IntercomProvider
          appId={"miqur2w2"}
          autoBoot
          // autoBootProps={{ verticalPadding: 100 }}
        >
          <IntercomProviderWithUser>
            <QueryClientProvider client={queryClient}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/clinic-list/:id" element={<ClinicsList />} />
                <Route path="/clinic/:id" element={<ClinicDetails />} />
                <Route path="/reservations" element={<ReservationList />} />
                <Route
                  path="/agent-reservations"
                  element={<AgentReservationList />}
                />
                <Route
                  path="/start-reservation"
                  element={<StartReservationScreen />}
                />
                <Route
                  path="/payment-success"
                  element={<PaymentSuccessScreen />}
                />
                <Route path="/why-gringo" element={<WhyGringoScreen />} />
                <Route path="/faqs" element={<FaqsScreen />} />
                <Route path="/how-it-works" element={<HowItWorksScreen />} />
                <Route path="/about" element={<AboutScreen />} />
                <Route path="/privacy" element={<PrivacyPolicyScreen />} />
                <Route path="/quote/" element={<QuoteScreen />} />
                <Route path="/mariano" element={<MarianoScreen />} />
              </Routes>
            </QueryClientProvider>
          </IntercomProviderWithUser>
        </IntercomProvider>
      </ClerkProvider>
    </NextUIProvider>
  );
}

export default App;
