/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { ArrowLeftCircle, ArrowRightCircle } from "lucide-react";

interface SlideshowCarouselProps {
  images: string[];
}

export const SlideshowCarousel = ({ images }: SlideshowCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ watchDrag: false });

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  return (
    <div className="embla2 relative h-full w-full " ref={emblaRef}>
      <div className="embla__container2 relative h-full w-full">
        {images.map((image, index) => {
          return (
            <div className="embla__slide2  h-full w-full">
              {/* <div className="flex flex-row content-around">
                <ArrowLeftCircle
                  onClick={scrollPrev}
                  className="hover:cursor-pointer"
                />
                <ArrowRightCircle
                  onClick={scrollNext}
                  className="hover:cursor-pointer"
                />
              </div> */}

              <img
                src={image ?? ""}
                className="object-cover bg-slate-200 mb-3"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
