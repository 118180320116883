import { Text, Card, Button } from "@nextui-org/react";
import { Reservation } from "../../localDB/types";
import useClinicDetails from "../../hooks/useClinicDetails";
import { Link } from "react-router-dom";
import useUserDetails from "../../hooks/useUserDetails";
import { reservationStateToText } from "../../localDB/utils";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { UpdateReservationInput, UpdateReservationMutation } from "../../API";
import { updateReservation } from "../../graphql/mutations";
import { ReservationState } from "../../models";
import moment from "moment";

interface AgentReservationRowProps {
  reservation: Reservation;
}

export const AgentReservationRow = ({
  reservation,
}: AgentReservationRowProps) => {
  const patient = useUserDetails(reservation.patientId);
  const { clinic, isLoading } = useClinicDetails(reservation?.clinicId ?? "");

  const updateReservationInput: UpdateReservationInput = {
    id: reservation.id,
    state: ReservationState.PENDING_PAYMENT,
  };
  const handleChangeStateToPay = async () => {
    reservation.state = ReservationState.PENDING_PAYMENT;
    await API.graphql<GraphQLQuery<UpdateReservationMutation>>({
      query: updateReservation,
      variables: { input: updateReservationInput },
    });
  };

  return (
    <div>
      <Card css={{ py: "$5", px: "$5" }}>
        <Card.Body>
          <div className="md:grid md:grid-cols-4 gap-4 mt-5">
            <Text b size={20}>{`Check-in: ${moment(
              reservation?.startDate
            ).format("MMMM DD, YYYY")}`}</Text>
            <div className="col-start-1 flex flex-col">
              <Text b size={20}>{`${clinic?.name}`}</Text>
              <Text
                size={20}
              >{`${clinic?.city.name}, ${clinic?.city?.Country?.name}`}</Text>
            </div>
            <div className="col-start-2">
              <Text
                b
                size={20}
              >{`${patient?.firstName} ${patient?.lastName}`}</Text>
            </div>
            <div className="col-start-3 flex flex-col items-center justify-start">
              {reservation?.servicesWithQuantities?.map((service) => (
                <Text
                  b
                  size={20}
                >{`${service?.name} x ${service?.quantity}`}</Text>
              ))}
            </div>
            <div className="col-start-4 flex flex-col ">
              <Text b size={20}>
                {reservationStateToText(reservation.state)}
              </Text>
            </div>
          </div>
          <div className="flex flex-col w-1/2 items-center self-center">
            {reservation.state === "CONFIRMING_DETAILS" ? (
              <Button
                flat
                auto
                as={Link}
                onPress={() => {
                  handleChangeStateToPay();
                }}
              >
                Change status to "Pending Payment"
              </Button>
            ) : undefined}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
