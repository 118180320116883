/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDentist = /* GraphQL */ `
  mutation CreateDentist(
    $input: CreateDentistInput!
    $condition: ModelDentistConditionInput
  ) {
    createDentist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      clinicID
      description
      facts {
        icon
        fact
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDentist = /* GraphQL */ `
  mutation UpdateDentist(
    $input: UpdateDentistInput!
    $condition: ModelDentistConditionInput
  ) {
    updateDentist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      clinicID
      description
      facts {
        icon
        fact
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDentist = /* GraphQL */ `
  mutation DeleteDentist(
    $input: DeleteDentistInput!
    $condition: ModelDentistConditionInput
  ) {
    deleteDentist(input: $input, condition: $condition) {
      id
      firstName
      lastName
      clinicID
      description
      facts {
        icon
        fact
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      email_address
      first_name
      last_name
      public_metadata
      streamToken
      clinicId
      stripeCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      email_address
      first_name
      last_name
      public_metadata
      streamToken
      clinicId
      stripeCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      email_address
      first_name
      last_name
      public_metadata
      streamToken
      clinicId
      stripeCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      ownerId
      message
      conversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      ownerId
      message
      conversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      ownerId
      message
      conversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          ownerId
          message
          conversationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          ownerId
          message
          conversationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      Messages {
        items {
          id
          ownerId
          message
          conversationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      patientId
      clinicId
      startDate
      state
      servicesWithQuantities {
        serviceId
        quantity
        name
        serviceCost
        serviceCompareCost
        __typename
      }
      totalCost
      paymentIntentId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      patientId
      clinicId
      startDate
      state
      servicesWithQuantities {
        serviceId
        quantity
        name
        serviceCost
        serviceCompareCost
        __typename
      }
      totalCost
      paymentIntentId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      patientId
      clinicId
      startDate
      state
      servicesWithQuantities {
        serviceId
        quantity
        name
        serviceCost
        serviceCompareCost
        __typename
      }
      totalCost
      paymentIntentId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCountry = /* GraphQL */ `
  mutation CreateCountry(
    $input: CreateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    createCountry(input: $input, condition: $condition) {
      id
      name
      emoji
      Cities {
        items {
          id
          name
          imageUrl
          countryID
          Country {
            id
            name
            emoji
            createdAt
            updatedAt
            __typename
          }
          Services {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCountry = /* GraphQL */ `
  mutation UpdateCountry(
    $input: UpdateCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    updateCountry(input: $input, condition: $condition) {
      id
      name
      emoji
      Cities {
        items {
          id
          name
          imageUrl
          countryID
          Country {
            id
            name
            emoji
            createdAt
            updatedAt
            __typename
          }
          Services {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCountry = /* GraphQL */ `
  mutation DeleteCountry(
    $input: DeleteCountryInput!
    $condition: ModelCountryConditionInput
  ) {
    deleteCountry(input: $input, condition: $condition) {
      id
      name
      emoji
      Cities {
        items {
          id
          name
          imageUrl
          countryID
          Country {
            id
            name
            emoji
            createdAt
            updatedAt
            __typename
          }
          Services {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCity = /* GraphQL */ `
  mutation CreateCity(
    $input: CreateCityInput!
    $condition: ModelCityConditionInput
  ) {
    createCity(input: $input, condition: $condition) {
      id
      name
      imageUrl
      countryID
      Country {
        id
        name
        emoji
        Cities {
          items {
            id
            name
            imageUrl
            countryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Services {
        items {
          id
          name
          cost
          compareCost
          perTooth
          cityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCity = /* GraphQL */ `
  mutation UpdateCity(
    $input: UpdateCityInput!
    $condition: ModelCityConditionInput
  ) {
    updateCity(input: $input, condition: $condition) {
      id
      name
      imageUrl
      countryID
      Country {
        id
        name
        emoji
        Cities {
          items {
            id
            name
            imageUrl
            countryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Services {
        items {
          id
          name
          cost
          compareCost
          perTooth
          cityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCity = /* GraphQL */ `
  mutation DeleteCity(
    $input: DeleteCityInput!
    $condition: ModelCityConditionInput
  ) {
    deleteCity(input: $input, condition: $condition) {
      id
      name
      imageUrl
      countryID
      Country {
        id
        name
        emoji
        Cities {
          items {
            id
            name
            imageUrl
            countryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Services {
        items {
          id
          name
          cost
          compareCost
          perTooth
          cityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      cost
      compareCost
      perTooth
      cityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      cost
      compareCost
      perTooth
      cityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      cost
      compareCost
      perTooth
      cityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClinic = /* GraphQL */ `
  mutation CreateClinic(
    $input: CreateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    createClinic(input: $input, condition: $condition) {
      id
      name
      subtitle
      City {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      Dentists {
        items {
          id
          firstName
          lastName
          clinicID
          description
          facts {
            icon
            fact
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lat
      lng
      testimonials {
        name
        date
        text
        __typename
      }
      createdAt
      updatedAt
      clinicCityId
      __typename
    }
  }
`;
export const updateClinic = /* GraphQL */ `
  mutation UpdateClinic(
    $input: UpdateClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    updateClinic(input: $input, condition: $condition) {
      id
      name
      subtitle
      City {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      Dentists {
        items {
          id
          firstName
          lastName
          clinicID
          description
          facts {
            icon
            fact
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lat
      lng
      testimonials {
        name
        date
        text
        __typename
      }
      createdAt
      updatedAt
      clinicCityId
      __typename
    }
  }
`;
export const deleteClinic = /* GraphQL */ `
  mutation DeleteClinic(
    $input: DeleteClinicInput!
    $condition: ModelClinicConditionInput
  ) {
    deleteClinic(input: $input, condition: $condition) {
      id
      name
      subtitle
      City {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      Dentists {
        items {
          id
          firstName
          lastName
          clinicID
          description
          facts {
            icon
            fact
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lat
      lng
      testimonials {
        name
        date
        text
        __typename
      }
      createdAt
      updatedAt
      clinicCityId
      __typename
    }
  }
`;
