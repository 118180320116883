/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDentist = /* GraphQL */ `
  query GetDentist($id: ID!) {
    getDentist(id: $id) {
      id
      firstName
      lastName
      clinicID
      description
      facts {
        icon
        fact
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDentists = /* GraphQL */ `
  query ListDentists(
    $filter: ModelDentistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDentists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        clinicID
        description
        facts {
          icon
          fact
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const dentistsByClinicID = /* GraphQL */ `
  query DentistsByClinicID(
    $clinicID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDentistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dentistsByClinicID(
      clinicID: $clinicID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        clinicID
        description
        facts {
          icon
          fact
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      email_address
      first_name
      last_name
      public_metadata
      streamToken
      clinicId
      stripeCustomerId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email_address
        first_name
        last_name
        public_metadata
        streamToken
        clinicId
        stripeCustomerId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      ownerId
      message
      conversationID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerId
        message
        conversationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByConversationID = /* GraphQL */ `
  query MessagesByConversationID(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConversationID(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        message
        conversationID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      Messages {
        items {
          id
          ownerId
          message
          conversationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Messages {
          items {
            id
            ownerId
            message
            conversationID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      patientId
      clinicId
      startDate
      state
      servicesWithQuantities {
        serviceId
        quantity
        name
        serviceCost
        serviceCompareCost
        __typename
      }
      totalCost
      paymentIntentId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        patientId
        clinicId
        startDate
        state
        servicesWithQuantities {
          serviceId
          quantity
          name
          serviceCost
          serviceCompareCost
          __typename
        }
        totalCost
        paymentIntentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCountry = /* GraphQL */ `
  query GetCountry($id: ID!) {
    getCountry(id: $id) {
      id
      name
      emoji
      Cities {
        items {
          id
          name
          imageUrl
          countryID
          Country {
            id
            name
            emoji
            createdAt
            updatedAt
            __typename
          }
          Services {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCountries = /* GraphQL */ `
  query ListCountries(
    $filter: ModelCountryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCountries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        emoji
        Cities {
          items {
            id
            name
            imageUrl
            countryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      imageUrl
      countryID
      Country {
        id
        name
        emoji
        Cities {
          items {
            id
            name
            imageUrl
            countryID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      Services {
        items {
          id
          name
          cost
          compareCost
          perTooth
          cityID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const citiesByCountryID = /* GraphQL */ `
  query CitiesByCountryID(
    $countryID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    citiesByCountryID(
      countryID: $countryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      cost
      compareCost
      perTooth
      cityID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        cost
        compareCost
        perTooth
        cityID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const servicesByCityID = /* GraphQL */ `
  query ServicesByCityID(
    $cityID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    servicesByCityID(
      cityID: $cityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        cost
        compareCost
        perTooth
        cityID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClinic = /* GraphQL */ `
  query GetClinic($id: ID!) {
    getClinic(id: $id) {
      id
      name
      subtitle
      City {
        id
        name
        imageUrl
        countryID
        Country {
          id
          name
          emoji
          Cities {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        Services {
          items {
            id
            name
            cost
            compareCost
            perTooth
            cityID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      Dentists {
        items {
          id
          firstName
          lastName
          clinicID
          description
          facts {
            icon
            fact
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      lat
      lng
      testimonials {
        name
        date
        text
        __typename
      }
      createdAt
      updatedAt
      clinicCityId
      __typename
    }
  }
`;
export const listClinics = /* GraphQL */ `
  query ListClinics(
    $filter: ModelClinicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClinics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        subtitle
        City {
          id
          name
          imageUrl
          countryID
          Country {
            id
            name
            emoji
            createdAt
            updatedAt
            __typename
          }
          Services {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        description
        Dentists {
          items {
            id
            firstName
            lastName
            clinicID
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        lat
        lng
        testimonials {
          name
          date
          text
          __typename
        }
        createdAt
        updatedAt
        clinicCityId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
