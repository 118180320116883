import { Reservation, ReservationState } from "./types";

export const reservationStateToText = (state: ReservationState) => {
  switch (state) {
    case "CONFIRMING_DETAILS":
      return "Confirming Details";
    case "PENDING_PAYMENT":
      return "Pending Payment";
    case "PLANNING_TRIP":
      return "Planning Trip";
    case "TRIP_IN_PROGRESS":
      return "Trip In Progress";
    case "COMPLETED":
      return "Completed";
    default:
      return "No State";
  }
};

export const calculateTotalSavings = (
  reservation?: Reservation
): { savingsCount: number; totalCost: number } => {
  let savingsCount = 0;
  let totalCost = 0;
  if (!reservation) return { savingsCount: 0, totalCost: 0 };
  reservation?.servicesWithQuantities?.forEach((service) => {
    savingsCount += service.serviceCompareCost * service.quantity;
    totalCost += service.serviceCost * service.quantity;
  });
  return { savingsCount, totalCost };
};
