import currency from "currency.js";
import { HOTEL_RATE_PER_NIGHT, serviceType } from "./QuoteScreen";
import { getAllOnX, getDentalImplant } from "./utils";

interface QuoteServiceCostSectionProps {
  serviceType: serviceType;
  diQuantity: string;
  fmiUpper: string;
  fmiLower: string;
}

export const renderServicePriceRow = (serviceName: string, cost: number) => (
  <>
    <div className="flex flex-row">
      <p className="pb-2 text-left text-lg md:text-xl text-gringoPrimaryDark">
        {serviceName}
      </p>
      <p className="pb-2 text-left text-lg md:text-xl text-gringoPrimaryDark ml-auto">
        {cost === 0
          ? "FREE"
          : currency(cost, {
              precision: 0,
            }).format()}
      </p>
    </div>
  </>
);

export const QuoteServiceCostSection = ({
  serviceType,
  diQuantity,
  fmiUpper,
  fmiLower,
}: QuoteServiceCostSectionProps) => {
  const renderFmiArchPriceRow = (arch: "lower" | "upper") =>
    arch === "lower"
      ? renderServicePriceRow(
          `Lower Arch: All-on-${fmiLower}`,
          getAllOnX(fmiLower).serviceCost
        )
      : renderServicePriceRow(
          `Upper Arch: All-on-${fmiUpper}`,
          getAllOnX(fmiUpper).serviceCost
        );

  return (
    <>
      {serviceType === "fmi" ? (
        <>
          {fmiLower === "0" ? undefined : renderFmiArchPriceRow("lower")}
          {fmiUpper === "0" ? undefined : renderFmiArchPriceRow("upper")}
        </>
      ) : (
        <>
          {diQuantity === "0"
            ? undefined
            : renderServicePriceRow(
                getDentalImplant(Number(diQuantity)).name,
                getDentalImplant(Number(diQuantity)).serviceCost
              )}
        </>
      )}
      {renderServicePriceRow("Hotel x 7 nights", 7 * HOTEL_RATE_PER_NIGHT)}
      {renderServicePriceRow("Patient Advocate", 0)}
      {renderServicePriceRow("Personal Driver", 0)}
    </>
  );
};
