import smileyYellow from "../assets/smileyYellowCropped.png";

export const EmbarkSection = () => {
  return (
    <div className="w-full h-64 bg-gringoPrimary flex items-center justify-center relative mt-16">
      <img
        src={smileyYellow}
        alt="GringoLogo"
        className="absolute top-0 left-1/2 transform -translate-x-1/2 w-auto h-64 z-10"
      />
      <h1 className="absolute text-center text-3xl md:text-5xl font-bold text-gringoPrimaryDark z-20 w-full">
        Embark on a life-changing journey with GrinGo
      </h1>
    </div>
  );
};
