/* eslint-disable @typescript-eslint/no-floating-promises */
import { Navbar, Link as NextLink, Avatar, Dropdown } from "@nextui-org/react";
import { SignInButton, useClerk, useUser } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";
import gringoLogo from "../assets/GringoLogoDarkBlue.png";
import gringoAvatar from "../assets/AvatarNv2.png";

export const NavBar = () => {
  const collapseItems = ["How It Works", "FAQs", "About"];
  const authenticatedUser = useUser();
  const authenticatedUserRole = authenticatedUser.user?.publicMetadata.role;
  const { signOut } = useClerk();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Navbar isBordered variant="sticky" maxWidth="fluid" disableBlur>
      <div className="md:max-w-6xl mx-auto w-full flex flex-row">
        <Navbar.Toggle showIn="xs" css={{ marginRight: "auto" }} />
        <Navbar.Brand>
          <a href="/">
            <img src={gringoLogo} alt="GringoLogo" className="w-36 h-auto" />
          </a>
        </Navbar.Brand>
        <Navbar.Content
          enableCursorHighlight
          hideIn="xs"
          variant="highlight-rounded"
          css={{
            alignSelf: "center",
            ml: "auto",
          }}
        >
          <Navbar.Link
            isActive={location.pathname === "/how-it-works"}
            href="/how-it-works"
          >
            How It Works
          </Navbar.Link>
          <Navbar.Link isActive={location.pathname === "/faqs"} href="/faqs">
            FAQs
          </Navbar.Link>
          <Navbar.Link isActive={location.pathname === "/about"} href="/about">
            About
          </Navbar.Link>
        </Navbar.Content>
        <Navbar.Content
          css={{
            marginLeft: "auto",
            justifyContent: "flex-end",
          }}
          className="md:w-36"
        >
          <Dropdown placement="bottom-right">
            <Navbar.Item>
              {authenticatedUser.isSignedIn ? (
                <Dropdown.Trigger>
                  <Avatar
                    // bordered
                    color="primary"
                    size="md"
                    src={gringoAvatar}
                  />
                </Dropdown.Trigger>
              ) : (
                <SignInButton mode="modal">
                  <Avatar
                    // bordered
                    color="primary"
                    size="md"
                    src={gringoAvatar}
                  />
                </SignInButton>
              )}
            </Navbar.Item>
            <Dropdown.Menu
              aria-label="User menu actions"
              color="secondary"
              onAction={(actionKey) => {
                if (actionKey === "signout") {
                  signOut();
                } else if (actionKey === "reservations") {
                  if (authenticatedUserRole === "agent") {
                    navigate("/agent-reservations");
                  } else {
                    navigate("/reservations");
                  }
                } else if (actionKey === "messages") {
                  navigate("/messages");
                }
              }}
            >
              <Dropdown.Item
                key="reservations"
                css={{
                  color: "$blue900",
                  "&:hover": {
                    background: "$blue200",
                  },
                  "&:focus": {
                    background: "$blue200",
                  },
                }}
              >
                Reservations
              </Dropdown.Item>
              <Dropdown.Item key="signout" withDivider color="error">
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Content>
        <Navbar.Collapse>
          {collapseItems.map((item, index) => {
            let path = "";

            switch (item) {
              case "FAQs":
                path = "/faqs";
                break;
              case "How It Works":
                path = "/how-it-works";
                break;
              case "About":
                path = "/about";
                break;
              case "Log Out":
                path = "/logout";
                break;
            }

            return (
              <Navbar.CollapseItem
                key={item}
                activeColor="primary"
                css={
                  {
                    // color: index === collapseItems.length - 1 ? "$error" : "",
                    // color: index === collapseItems.length - 1 ? "$error" : "",
                  }
                }
                isActive={path === location.pathname}
              >
                <NextLink
                  color="inherit"
                  css={{
                    minWidth: "100%",
                  }}
                  href={`${path}`}
                >
                  {item}
                </NextLink>
              </Navbar.CollapseItem>
            );
          })}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};
