import moment, { Moment } from "moment";
import { ServiceIdWithQuantity } from "../../localDB/types";

export const calcServiceRange = (key: string | null, addMonths?: number) => {
  switch (key) {
    case "in2Weeks":
      return adjustDates("weeks", 2, addMonths);
    case "in1Month":
      return adjustDates("months", 1, addMonths);
    case "in23Months":
      return adjustDates("months", 2, addMonths);
    case "notSure":
    default:
      return "TBD";
  }
};

export const getDentalImplant = (qty: number): ServiceIdWithQuantity => {
  return {
    serviceId: "c837fc71-05c3-4dd5-afd5-a98cece1a433",
    quantity: qty,
    name: `Porcelain Dental Implant x ${qty}`,
    serviceCost: 1500 * qty,
    serviceCompareCost: 5000 * qty,
  };
};

export const getAllOnX = (x: string | null): ServiceIdWithQuantity => {
  switch (x) {
    case "4":
      return {
        serviceId: "b7fc3ce1-54fd-453e-9373-9fd57a0dbb14",
        quantity: 1,
        name: "All-On-4",
        serviceCost: 7500,
        serviceCompareCost: 20000,
      };
    case "6":
      return {
        serviceId: "59bba224-92f8-40fd-83ea-3a2ad5f2093d",
        quantity: 1,
        name: "All-On-6",
        serviceCost: 10500,
        serviceCompareCost: 25000,
      };
    case "8":
      return {
        serviceId: "2f153273-abb1-46c0-a365-84ac2d954420",
        quantity: 1,
        name: "All-On-8",
        serviceCost: 13500,
        serviceCompareCost: 30000,
      };
    default:
      return {
        serviceId: "",
        quantity: 1,
        name: "undefined",
        serviceCost: 0,
        serviceCompareCost: 0,
      };
  }
};

const adjustDates = (
  period: "weeks" | "months",
  value: number,
  additionalMonths?: number
) => {
  const current = moment();
  const end = moment();
  current.add(value, period);
  end.add(value, period).add(7, "days");
  if (additionalMonths) {
    current.add(additionalMonths, "months");
    end.add(additionalMonths, "months");
  }
  return getRange(current, end);
};
const getRange = (startDate: Moment, endDate: Moment) => {
  const startDay = startDate.date();
  const endDay = endDate.date();
  const month = startDate.format("MMMM");
  const year = startDate.year();
  const dateString = `${month} ${startDay}-${endDay}, ${year}`;
  return dateString;
};

export const getFirstAppointmentDate = (key: string | null) => {
  const currentDate = moment();

  switch (key) {
    case "in2Weeks":
      return currentDate.add(2, "weeks");
    // return currentDate.add(2, "weeks").format("MMMM DD, YYYY");
    case "in1Month":
      return currentDate.add(1, "month");
    case "in23Months":
      return currentDate.add(2, "months");
    case "notSure":
    default:
      return undefined;
  }
};
