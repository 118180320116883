interface BlueCardProps {
  title: string;
  description?: string;
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const BlueCard = ({
  title,
  description,
  IconComponent,
}: BlueCardProps) => {
  return (
    <>
      <div className="border-gringoPrimaryDark border-4 rounded-[50px] p-5">
        <IconComponent color="#0e0e40" className="h-12 w-12 mb-2" />
        <p className="text-left text-gringoPrimary text-lg md:text-2xl font-bold">
          {title}
        </p>
        {description ? (
          <p className="text-left mt-3 text-lg">{description}</p>
        ) : undefined}
      </div>
    </>
  );
};
