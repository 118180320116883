import { CheckCircle } from "lucide-react";
import Layout from "../Layout";
import Section from "../Section";

export const PaymentSuccessScreen = () => {
  return (
    <Layout>
      <Section>
        <div className="flex flex-col items-center align-center mb-10">
          <span className="font-semibold text-2xl md:text-3xl mt-10 text-center">
            You have succesfully started your reservation. Your Patient Advocate
            will contact you shortly.
          </span>
          <CheckCircle color="#0072F5" className="h-36 w-36 my-5" />
          <span className="text-md font-semibold mt-5 mb-2">
            Your Patient Advocate will help you with:
          </span>
          <div className="flex flex-col">
            <span className="text-md">
              ✓ Any questions/concerns you may have
            </span>
            <span className="text-md">
              ✓ Service plan coordination with clinic
            </span>
            <span className="text-md">✓ Flight reservations</span>
            <span className="text-md">✓ Tour reservations</span>
          </div>
        </div>
      </Section>
    </Layout>
  );
};
