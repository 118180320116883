/* eslint-disable jsx-a11y/alt-text */
// ImageChanger.tsx
import gringoDentist from "../assets/gringoDentist.jpg";
import gringoHiking from "../assets/gringoHiking.jpg";

import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

interface ImageChangeProps {
  className?: string;
}
const ImageChanger = ({ className }: ImageChangeProps) => {
  const [showImageOne, setShowImageOne] = useState<boolean>(true);
  const imageDuration = 2500; // Display each image for 2 seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setShowImageOne((prev) => !prev);
    }, imageDuration);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  const currentImage = showImageOne ? gringoDentist : gringoHiking;

  return (
    <div>
      <SwitchTransition mode={"out-in"}>
        <CSSTransition classNames="fade" timeout={500} key={currentImage}>
          <img src={currentImage} className={className} />
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default ImageChanger;
