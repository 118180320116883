import { GraphQLQuery, GraphQLResult } from "@aws-amplify/api";
import {
  GetClinicQuery,
  ListServicesQuery,
  // ServicesByClinicIDQuery,
  GetUsersQuery,
  GetReservationQuery,
  GetCityQuery,
} from "../API";
import { Service as ServiceGraphQL } from "../models";
import {
  City,
  Clinic,
  Dentist,
  Message,
  Reservation,
  Service,
  Testimonial,
  User,
} from "./types";

export function mapService(service: ServiceGraphQL | null): Service {
  return {
    id: service?.id ?? "",
    name: service?.name ?? "",
    cost: service?.cost ?? 0,
    compareCost: service?.compareCost ?? 0,
    perTooth: service?.perTooth ?? false,
  };
}

// export function mapServicesByClinicID(
//   services: ServicesByClinicIDQuery
// ): Service[] {
//   const servicesList: Service[] = [];

//   services.servicesByClinicID?.items.map((item) => {
//     if (item !== null) {
//       const newService: Service = {
//         id: item.id,
//         name: item.name,
//         cost: item.cost,
//         compareCost: item.compareCost,
//         perTooth: item.perTooth,
//       };
//       servicesList.push(newService);
//     }
//   });

//   return servicesList;
// }

export function mapServicesListToServicesList(
  services: ListServicesQuery
): Service[] {
  const servicesList: Service[] = [];

  services.listServices?.items.map((item) => {
    if (item !== null) {
      const newService: Service = {
        id: item.id,
        name: item.name,
        cost: item.cost,
        compareCost: item.compareCost,
        perTooth: item.perTooth,
      };
      servicesList.push(newService);
    }
  });

  return servicesList;
}

export function mapGetUserResponse(
  user: GraphQLResult<GraphQLQuery<GetUsersQuery>>
): User | undefined {
  const id = user?.data?.getUsers?.id;
  const firstName = user?.data?.getUsers?.first_name;
  const lastName = user?.data?.getUsers?.last_name;
  const email = user?.data?.getUsers?.email_address;
  const metadata = user?.data?.getUsers?.public_metadata;
  const streamToken = user?.data?.getUsers?.streamToken;
  const stripeCustomerId = user?.data?.getUsers?.stripeCustomerId;
  const clinicId = user?.data?.getUsers?.clinicId;

  if (
    id === undefined ||
    firstName === undefined ||
    lastName === undefined ||
    email === undefined ||
    metadata === undefined ||
    metadata === null
  ) {
    return undefined;
  }

  return {
    id,
    firstName,
    lastName,
    email,
    metadata: JSON.parse(metadata),
    streamToken: streamToken ?? undefined,
    stripeCustomerId: stripeCustomerId ?? undefined,
    clinicId: clinicId ?? undefined,
  };
}

export function mapGetCityResponse(
  city: GraphQLResult<GraphQLQuery<GetCityQuery>>
): City | undefined {
  const id = city?.data?.getCity?.id;
  const name = city?.data?.getCity?.name;
  const imageUrl = city?.data?.getCity?.imageUrl;
  const countryId = city?.data?.getCity?.countryID;
  const services = city?.data?.getCity?.Services;

  if (
    id === undefined ||
    name === undefined ||
    imageUrl === undefined ||
    countryId === undefined ||
    services === undefined ||
    services === null
  ) {
    return undefined;
  }

  const Services = services.items?.filter(
    (service) => service !== undefined && service !== null
  ) as Service[];
  return {
    id,
    name,
    imageUrl,
    Services,
  };
}

export function mapGetClinicResponse(
  clinic: GraphQLResult<GraphQLQuery<GetClinicQuery>>
): Clinic | undefined {
  const id = clinic?.data?.getClinic?.id;
  const name = clinic?.data?.getClinic?.name;
  const subtitle = clinic?.data?.getClinic?.subtitle;
  const description = clinic?.data?.getClinic?.description;
  const city = clinic?.data?.getClinic?.City;
  const dentists = clinic?.data?.getClinic?.Dentists;
  const testimonials = clinic?.data?.getClinic?.testimonials;
  const latitude = clinic?.data?.getClinic?.lat;
  const longitude = clinic?.data?.getClinic?.lng;

  if (
    id === undefined ||
    name === undefined ||
    subtitle === undefined ||
    description === undefined ||
    clinic === undefined ||
    city === undefined ||
    city?.Services === undefined ||
    dentists === undefined ||
    testimonials === undefined
  ) {
    return undefined;
  }

  const filteredCity = {
    ...city,
    Services: city?.Services?.items?.filter(
      (service) => service !== undefined && service !== null
    ) as Service[],
  };

  return {
    id,
    name,
    subtitle,
    description,
    city: filteredCity,
    dentists: dentists?.items as Dentist[],
    testimonials: testimonials as Testimonial[],
    latitude: latitude,
    longitude: longitude,
  };
}

export function mapGetReservationResponse(
  reservation: GraphQLResult<GraphQLQuery<GetReservationQuery>>
): Omit<Reservation, "Conversation"> | undefined {
  const id = reservation?.data?.getReservation?.id;
  const patientId = reservation?.data?.getReservation?.patientId;
  const clinicId = reservation?.data?.getReservation?.clinicId;
  const startDate = reservation?.data?.getReservation?.startDate;
  const totalCost = reservation?.data?.getReservation?.totalCost;
  const state = reservation?.data?.getReservation?.state;
  const services = reservation?.data?.getReservation?.servicesWithQuantities;

  if (
    id === undefined ||
    patientId === undefined ||
    clinicId === null ||
    startDate === undefined ||
    totalCost === undefined ||
    state === undefined ||
    services === undefined
  ) {
    return undefined;
  }
  return {
    id,
    patientId,
    clinicId,
    startDate,
    totalCost,
    state,
    servicesWithQuantities: services,
  };
}

export function mapMessage(message: any): Message {
  const newMessage: Message = {
    id: message.id,
    ownerId: message.ownerId,
    message: message.message,
    createdAt: message.createdAt,
  };
  return newMessage;
}

export function mapMessages(conversationMessages: any): Message[] {
  const messages: Message[] = [];

  conversationMessages.items.map((item: any) => {
    if (item !== null) {
      const newMessage: Message = {
        id: item.id,
        ownerId: item.ownerId,
        message: item.message,
        createdAt: item.createdAt,
      };
      messages.push(newMessage);
    }
  });

  return messages;
}
