/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import { Text } from "@nextui-org/react";

interface MobileCarouselProps {
  images: string[];
}

export const MobileCarousel = ({ images }: MobileCarouselProps) => {
  const [emblaRef] = useEmblaCarousel();

  return (
    <div className="embla" ref={emblaRef}>
      <div className="embla__container">
        {images.map((image, index) => {
          return (
            <div className="embla__slide relative h-72 w-full">
              <img src={image ?? ""} className="object-cover bg-slate-200" />
              <div className="absolute bottom-10 right-2 bg-black bg-opacity-70 px-2 py-1 rounded-xl">
                <Text color="white">{`${index + 1}/${images.length}`}</Text>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
