import React, { ReactNode } from "react";

interface SectionProps {
  children: ReactNode;
  className?: string;
}

const Section: React.FC<SectionProps> = ({ children, className }) => {
  return (
    //      "max-w-6xl mx-auto px-4 mt-5 sm:mt-10 sm:items-start " + className
    <div className={"max-w-6xl mx-auto px-4 sm:items-start " + className}>
      {children}
    </div>
  );
};

export default Section;
