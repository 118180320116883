export const HomeSteps = () => {
  return (
    <div className="flex flex-col md:flex-row md:border-y md:border-gringoPrimary p-5">
      <div
        className={`flex flex-col w-full md:max-w-full md:rounded-r-full md:border-r-2 border-gringoPrimary`}
      >
        <div className={`rounded-3xl p-5 flex flex-col`}>
          <div className="flex flex-row items-center my-4">
            <div className="bg-gringoPrimary w-10 h-10 rounded-full flex items-center justify-center">
              <p className="font-bold text-2xl text-white">1</p>
            </div>
            <p className="text-left ml-2 font-bold text-2xl text-gringoPrimary">
              Book
            </p>
          </div>
          <p className="text-left ml-2 text-md text-gringoPrimaryDark break-normal">
            Pay the $99 security deposit (fully refundable) and start your
            reservation through our platform. Don’t worry, the first appointment
            date and services are estimates.
          </p>
        </div>
      </div>
      <div
        className={`flex flex-col w-full md:max-w-full md:rounded-r-full md:border-r-2 border-gringoPrimary`}
      >
        <div className={`rounded-3xl p-5 flex flex-col`}>
          <div className="flex flex-row items-center my-4">
            <div className="bg-gringoPrimary w-10 h-10 rounded-full flex items-center justify-center">
              <p className="font-bold text-2xl text-white">2</p>
            </div>
            <p className="text-left ml-2 font-bold text-2xl text-gringoPrimary">
              Plan
            </p>
          </div>
          <p className="text-left ml-2 text-md text-gringoPrimaryDark break-normal">
            You'll get a personalized Patient Advocate to guide you throughout
            the entire process. Your Patient Advocate will help you schedule
            your dentist appointments, determining the best clinic for you, and
            supporting all your travel logistics through our GrinGo partners.
          </p>
        </div>
      </div>
      <div className={`flex flex-col w-full md:max-w-fullrounded-r-full`}>
        <div className={`rounded-3xl p-5 flex flex-col`}>
          <div className="flex flex-row items-center my-4">
            <div className="bg-gringoPrimary w-10 h-10 rounded-full flex items-center justify-center">
              <p className="font-bold text-2xl text-white">3</p>
            </div>
            <p className="text-left ml-2 font-bold text-2xl text-gringoPrimary">
              Travel & Smile
            </p>
          </div>
          <p className="text-left ml-2 text-md text-gringoPrimaryDark break-normal">
            Fly to your destination. Your GrinGo Driver and Patient Advocate
            will welcome you at the airport and take you to your hotel. Then,
            they'll come with you to every appointment.
          </p>
        </div>
      </div>
    </div>
  );
};
