import { useEffect, useMemo, useState } from "react";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { listReservations } from "../../graphql/queries";
import { ListReservationsQuery } from "../../API";
import Layout from "../Layout";
import Section from "../Section";
import { ReservationRow } from "./ReservationRow";
import { Reservation } from "../../localDB/types";
import { useUser } from "@clerk/clerk-react";
import { SyncLoader } from "react-spinners";
import { AgentReservationRow } from "./AgentReservationRow";

const AgentReservationList = () => {
  const [incomingReservations, setIncomingReservations] =
    useState<Reservation[]>();
  const [loading, setLoading] = useState(true);

  const user = useUser();
  const clinicId = useMemo(
    () => user?.user?.publicMetadata.clinicId,
    [user?.user?.publicMetadata.clinicId]
  );

  useEffect(() => {
    const listAllReservations = async () => {
      // if (!clinicId) return;
      let response;

      response = await API.graphql<GraphQLQuery<ListReservationsQuery>>({
        query: listReservations,
      });

      return response;
    };
    listAllReservations().then((response) => {
      setIncomingReservations(
        response?.data?.listReservations?.items as Reservation[]
      );
      setLoading(false);
    });
  }, [clinicId]);

  return (
    <Layout>
      <Section>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="flex justify-center items-center h-screen">
              <SyncLoader color="#0068d3" speedMultiplier={0.7} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex">
              <div className="text-left">
                <h1 className="font-bold text-2xl">Reservations</h1>
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {incomingReservations
                ? incomingReservations.map((reservation) => (
                    <div>
                      <AgentReservationRow
                        key={reservation.id}
                        reservation={reservation}
                      ></AgentReservationRow>
                    </div>
                  ))
                : undefined}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default AgentReservationList;
