import { Text, Card } from "@nextui-org/react";
import { CalendarCheck, Play } from "lucide-react";
import { ReservationState, Reservation } from "../../localDB/types";
import useClinicDetails from "../../hooks/useClinicDetails";
import moment from "moment";
import currency from "currency.js";

interface ReservationRowProps {
  reservation: Reservation;
}

export const reservationState = (reservationState: ReservationState) => {
  if (reservationState === "CONFIRMING_DETAILS")
    return (
      <div className="flex flex-row items-start ">
        <Play aria-label="icon" />
        <h3 className="text-lg text-gringoPrimaryDark ml-2">
          Reservation started
        </h3>
      </div>
    );
  if (reservationState === "PLANNING_TRIP")
    return (
      <>
        <CalendarCheck aria-label="icon" />
        <h3 className="text-lg text-gringoPrimaryDark ml-2">Confirmed</h3>
      </>
    );
  return "Unknown";
};

export const ReservationRow = ({ reservation }: ReservationRowProps) => {
  const { clinic, isLoading } = useClinicDetails(reservation?.clinicId ?? "");

  return (
    <div>
      <Card css={{ py: "$7", px: "$7", mb: "$5", borderRadius: "50px" }}>
        <Card.Body>
          <h3 className="text-base text-left text-gray-400">
            {`Reservation #${reservation?.id}`}
          </h3>
          <div className="md:grid md:grid-cols-5 gap-5 mt-5">
            <div className="col-start-1 flex flex-col items-left justify-start">
              <h2 className="text-lg text-left text-gringoPrimaryDark">
                Check-in Date
              </h2>
              <h3 className="text-lg text-left text-gringoPrimaryDark">
                {`${moment(reservation?.startDate).format("MMMM DD, YYYY")}`}
              </h3>
            </div>
            <div className="col-start-2 flex flex-col items-left justify-start">
              <h2 className="text-lg text-left text-gringoPrimaryDark">
                Clinic
              </h2>
              <h3 className="text-lg text-left text-gringoPrimaryDark">
                {`${clinic?.name ?? "TBD"}`}
              </h3>
            </div>

            <div className="col-start-3 flex flex-col items-left justify-start">
              <h2 className="text-lg text-left text-gringoPrimaryDark">
                Services
              </h2>
              {reservation?.servicesWithQuantities.map((service) => {
                return (
                  <h3 className="text-lg text-left text-gringoPrimaryDark">
                    {`• ${service.name} x ${service.quantity}`}
                  </h3>
                );
              })}
            </div>
            <div className="col-start-4 flex flex-col items-left  justify-start">
              <h2 className="text-lg text-left text-gringoPrimaryDark">
                Estimated Cost
              </h2>
              <h3 className="text-lg text-left text-gringoPrimaryDark">
                {currency(reservation.totalCost, { precision: 0 }).format() ??
                  "TBD"}
              </h3>
            </div>
            <div className="col-start-5 flex flex-col items-left justify-start">
              <h2 className="text-lg text-left text-gringoPrimaryDark">
                Status
              </h2>
              {reservationState(reservation.state)}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
