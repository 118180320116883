import Layout from "./Layout";
import Section from "./Section";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import gringoLogo from "../assets/GringoLogoDarkBlue.png";
import { PopupButton } from "@typeform/embed-react";
import { Button } from "@nextui-org/react";
import { useRef } from "react";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  blueText: {
    color: "#0083f0",
  },
  logo: {
    width: 150,
    marginVertical: 15,
  },
  centered: {
    textAlign: "center",
    marginBottom: 10,
  },
  table: {
    // display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
  },
  infoSection: {
    marginVertical: 15,
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderColor: "#0083f0",
  },
  detail: {
    marginTop: 5,
    fontSize: 14,
  },
  p15: {
    paddingTop: 15,
  },
  textSize12: {
    fontSize: 12,
  },
});

const Doc = () => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src={gringoLogo} />
        <Text style={[styles.centered, styles.blueText, styles.p15]}>
          Full Mouth Implants (All-On-4/6/8) Estimate
        </Text>
        <Text style={[styles.centered, styles.blueText]}>
          Prepared for: MARIANO
        </Text>
        <View style={styles.infoSection}>
          <Text style={styles.detail}>Prepared on:</Text>
          <Text style={styles.detail}>Valid until:</Text>
          <Text style={styles.detail}>Estimated First Travel Date:</Text>
          <Text style={styles.detail}>Estimated Second Travel Date:</Text>
          <Text style={styles.detail}>
            Dental Clinic: German Dental Clinic (Lima, Peru)
          </Text>
        </View>
      </Page>
    </Document>
  );
};
const MarianoScreen = () => {
  const formRef = useRef<any>(null);

  return (
    <Layout>
      <PopupButton
        id="HubYNANx"
        style={{ fontSize: 20 }}
        ref={formRef}
        fullScreen
      >
        <Button
          shadow
          onClick={() => {
            formRef.current?.open();
          }}
          rounded
          size="lg"
          css={{ color: "$white", fontWeight: "$semibold" }}
        >
          Get a Free Quote
        </Button>
        <span className="text-sm mt-3 text-gringoPrimary">
          Takes 15 seconds
        </span>
      </PopupButton>
      {/* <div className="flex h-screen w-full bg-yellow-800">
        <PDFViewer children={<Doc />} width={"100%"} height={"100%"} />
      </div> */}
    </Layout>
  );
};

export default MarianoScreen;
