import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";
import { GetUsersQuery } from "../API";
import { getUsers } from "../graphql/queries";
import { mapGetUserResponse } from "../localDB/mappers";
import { User } from "../localDB/types";

export default function useUserDetails(id: string): User | undefined {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (id === "") return;
    async function fetchUser() {
      try {
        const response = await API.graphql<GraphQLQuery<GetUsersQuery>>(
          graphqlOperation(getUsers, { id })
        );
        setUser(mapGetUserResponse(response));
      } catch (error) {
        console.log("Error fetching user:", error);
      }
    }

    fetchUser();
  }, [id]);

  return user;
}
