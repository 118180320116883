import { useEffect, useState } from "react";
import Layout from "../Layout";
import Section from "../Section";
import { API } from "aws-amplify";
import { ListClinicsQuery } from "../../API";
import { listClinics } from "../../graphql/queries";
import { GraphQLQuery } from "@aws-amplify/api";
import { Clinic } from "../../localDB/types";
import { ClinicCard } from "./ClinicCard";
import { useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";

const ClinicList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [clinics, setClinics] = useState<Clinic[]>([]);

  useEffect(() => {
    const getResponse = async () => {
      if (id === undefined) return;
      const response: any = await API.graphql<GraphQLQuery<ListClinicsQuery>>({
        query: listClinics,
        variables: {
          filter: {
            clinicCityId: { eq: id },
          },
        },
      });
      return response;
    };
    getResponse().then((response) => {
      setClinics(response.data.listClinics?.items as Clinic[]);
      if (clinics.length !== 0) {
        setLoading(false);
      }
    });
  }, [clinics.length, id]);
  return (
    <Layout>
      <Section>
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="flex justify-center items-center h-screen">
              <SyncLoader color="#0068d3" speedMultiplier={0.7} />
            </div>
          </div>
        ) : (
          <>
            <div className="flex">
              <div className="text-left">
                <h1 className="font-bold text-2xl">
                  Qualified Dental Clinics in Lima, Peru 🇵🇪
                </h1>
              </div>
            </div>
            <div className="grid justify-center gap-5 md:grid-cols-2 lg:grid-cols-4">
              {clinics
                ? clinics.map((clinic) => (
                    <div key={clinic.id}>
                      <ClinicCard clinic={clinic} />
                    </div>
                  ))
                : undefined}
            </div>
          </>
        )}
      </Section>
    </Layout>
  );
};

export default ClinicList;
