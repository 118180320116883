import { Coffee, DollarSign, HelpingHand, Medal, Plane } from "lucide-react";
import { HomeIconWithText } from "./Home/HomeIconWithText";
import Layout from "./Layout";
import Section from "./Section";
import { LeftAlignedIconWithText } from "./LeftAlignedIconWithText";

const WhyGringoScreen = () => {
  return (
    <Layout>
      <Section>
        <>
          <div className="flex flex-col">
            <h1 className="font-semibold text-3xl text-gringoPrimary font-berlingske mt-20">
              Why GrinGo
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-1 gap-5 py-5">
              <LeftAlignedIconWithText
                IconComponent={HelpingHand}
                title="Personalized, Start-to-Finish Support"
                description="Enjoy the dedicated assistance of a personalized Patient Advocate who will handle all the details before your trip, answer your questions, and ensure accurate pricing and diagnosis. Most importantly, your Patient Advocate will accompany you to every clinic appointment along with a GrinGo driver."
              />
              <LeftAlignedIconWithText
                IconComponent={Medal}
                title="Carefully Curated Clinics"
                description="Receive expert assistance in selecting a top-tier dental clinic that perfectly matches your specific needs, preferences, and budget."
              />
              <LeftAlignedIconWithText
                IconComponent={DollarSign}
                title="Maximize Savings"
                description="Benefit from our commitment to securing the best pricing and ensuring accurate diagnoses to maximize your satisfaction and savings."
              />
              <LeftAlignedIconWithText
                IconComponent={Coffee}
                title="Seamless Reservation Management"
                description="
                Experience a worry-free process as we take care of appointment coordination and manage your treatment schedule, ensuring a seamless experience."
              />
              <LeftAlignedIconWithText
                IconComponent={Plane}
                title="Comprehensive Travel Assistance"
                description="Enjoy comprehensive travel assistance, including the presence of a GrinGo driver upon your arrival, accommodation arrangements at one of our partner locations, and continuous support throughout your stay. We can also connect you with our partner travel agencies for personalized tours."
              />
            </div>
          </div>
        </>
      </Section>
    </Layout>
  );
};

export default WhyGringoScreen;
