import currency from "currency.js";
import { ServiceWithQuantity } from "./Clinic/ClinicDetails";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

interface ServiceRowProps {
  service: ServiceWithQuantity;
  onChange: (value: string) => void;
}
export const ServiceRow = ({ service, onChange }: ServiceRowProps) => {
  return (
    <div className="grid grid-cols-3 gap-4 mt-5">
      <div className="col-start-1 flex items-center">
        <h2 className="text-lg text-gringoPrimaryDark font-normal">
          {service.name}
        </h2>
      </div>
      <div className="col-start-2 flex items-center justify-center">
        <h2 className="text-lg text-gringoPrimaryDark text-right textgringoPrimary font-normal">
          {currency(service.cost, { precision: 0 }).format()}
        </h2>
      </div>
      <div className="col-start-3 flex items-center justify-end">
        <Select onValueChange={onChange}>
          <SelectTrigger className="w-16 border-gringoPrimaryDark border-2 text-gringoPrimaryDark">
            <SelectValue placeholder="0" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="0">0</SelectItem>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">2</SelectItem>
            {service.perTooth ? (
              <>
                <SelectItem value="3">3</SelectItem>
                <SelectItem value="4">4</SelectItem>
                <SelectItem value="5">5</SelectItem>
                <SelectItem value="6">6</SelectItem>
              </>
            ) : undefined}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
