import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { GetCityQuery } from "../API";
import { getCity } from "../graphql/queries";
import { City } from "../localDB/types";
import { GraphQLQuery } from "@aws-amplify/api";
import { mapGetCityResponse } from "../localDB/mappers";

const getCityById = (id: string) =>
  API.graphql<GraphQLQuery<GetCityQuery>>({
    query: getCity,
    variables: {
      id: "3c9389f3-64f5-4b16-bf03-4cd2b3e3eff0",
    },
  }).then((response) => {
    const city = mapGetCityResponse(response);
    if (!city) {
      throw new Error("City not found");
    }
    return city;
  });

export default function useCityDetails(id: string) {
  const {
    data: city,
    status,
    isLoading,

    error,
  } = useQuery<City, Error>(["clinic", id], () => getCityById(id), {
    enabled: Boolean(id),
  });

  return { city, status, isLoading, error };
}
